import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import Modal from "../Modal/Modal";
import "./Signup.css";

export default function SUCheckComponent({ setStep }) {
  const [Checked1, setChecked1] = useState(false);
  const [Checked2, setChecked2] = useState(false);
  const checkHandler = (e) => {
    if (e.target.id == "chk_1") {
      setChecked1(!Checked1);
    } else if (e.target.id == "chk_2") {
      setChecked2(!Checked2);
    }
  };

  // 모달 관련 함수 선언,
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();

  const modalHandler = () => {
    if (Checked1 & Checked2) {
      setStep(2);
    } else if (!Checked1) {
      setModal(!modal);
      setContentID(1);
    } else if (!Checked2) {
      setModal(!modal);
      setContentID(2);
    }
  };

  return (
    <div className="SUCheckComponent">
      <div className="checkbox-container">
        <input
          type={"checkbox"}
          id="chk_1"
          onChange={(e) => checkHandler(e)}
        ></input>
        <label htmlFor="chk_1">
          {Checked1 ? (
            <img
              src={ImageComponent.CheckBox_On}
              className="img_checkBox"
            ></img>
          ) : (
            <img
              src={ImageComponent.CheckBox_Off}
              className="img_checkBox"
            ></img>
          )}
        </label>
        <label htmlFor="chk_1">
          <span className="chk_1">애드파워 이용약관 동의 (필수)</span>
        </label>
      </div>
      <div className="terms">
        <div>
          <h3>제1장 총칙</h3>
          <p>
            <strong>제1조(목적)</strong>&nbsp;
          </p>
          <br></br>
          <p>
            이 약관은 애드파워가 운영하는 애드파워 사이트 (이하 “사이트”라
            한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를
            이용함에 있어 홈페이지와 이용자의 권리・의무 및 책임사항을 규정함을
            목적으로 합니다.
          </p>
          <p>
            <br></br>
          </p>
          <p>
            <strong>제2조(정의)</strong>
          </p>
          <ol>
            <li>
              <p>
                “사이트”란 애드파워가 재화 또는 서비스를 이용자에게 제공하기
                위하여 컴퓨터 등 정보통신설비를 이용하여 회원 간 교류를 할 수
                있도록 설정한 가상의 영업장을 말합니다.
              </p>
            </li>
            <li>
              <p>
                “이용자”란 “사이트”에 접속하여 이 약관에 따라 서비스를 제공받는
                회원 및 비회원을 말합니다.
              </p>
            </li>
            <li>
              <p>
                ‘회원’이라 함은 “사이트”에 회원등록을 한 자로서, 계속적으로
                “사이트”가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
              </p>
            </li>
            <li>
              <p>
                ‘비회원’이라 함은 회원에 가입하지 않고 “사이트”가 제공하는
                서비스를 이용하는 자를 말합니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제3조 (약관의 명시와 효력 및 개정)</strong>
          </p>
          <ol>
            <li>
              <p>
                애드파워는 본 약관의 구체적 내용을 회원이 연결화면을 통하여 볼
                수 있도록 합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및
                정보보호 등에 관한 법률』 등 관련 법령을 위배하지 않는 범위에서
                이 약관을 개정할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                애드파워가 약관을 개정할 경우에는 적용 일자 및 개정 사유를
                명시하여 현행 약관과 함께 그 적용 일자 7일 이전부터 적용 일자
                전일까지 사이트의 초기 화면 등에 공지합니다. 다만, 회원에게
                불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전
                유예기간을 두고 공지합니다. 애드파워가 개정약관을 공지하면서
                회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된
                것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로
                거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한
                것으로 봅니다.
              </p>
            </li>
            <li>
              <p>
                제3항에 의해 변경된 약관은 법령에 특별한 규정이나 기타 부득이한
                사유가 없는 한 그 적용 일자 이전으로 소급하여 적용되지 않습니다.
              </p>
            </li>
            <li>
              <p>
                회원은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에
                동의하지 않을 경우 언제든지 자유롭게 서비스 이용을 중단하고
                탈퇴할 수 있습니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제4조 (약관 외 준칙)</strong>
          </p>
          <br></br>
          <p>
            이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 『약관의
            규제에 관한 법률』 및 관계 법령 및 상관례에 따릅니다.
          </p>
          <p>
            <br></br>
          </p>
          <p>
            <strong>제5조 (이용계약의 성립)</strong>
          </p>
          <ol>
            <li>
              <p>
                이용계약은 회원이 애드파워가 정한 가입 양식에 따라 애드파워에서
                제공한 필요사항을 기재하여야 합니다. 메일 인증 및 회원 정보를
                기입하고 약관에 동의하여야 체결됩니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중
                다음 각 호에 해당하지 않는 한 회원으로 가입을 승인합니다. 다만,
                애드파워가 회원 가입신청의 승인을 거부하거나 유보하는 경우 이를
                회원에게 알립니다.
              </p>
            </li>
          </ol>
          <p className="marginleftSU">
            가. 가입 신청자가 이 약관 제8조 제2항에 의하여 이전에 회원자격을
            상실한 적이 있는 경우
          </p>
          <p className="marginleftSU">
            나. 등록내용에 허위, 기재 누락, 오기가 있는 경우
          </p>
          <ol start="3">
            <li>
              <p>
                이용계약의 성립 시기는 애드파워가 가입 완료를 신청 절차상에서
                표시한 시점으로 합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 회원에 대해 애드파워 정책에 따라 등급을 정하고 이에
                따라 이용 서비스에 차등을 둘 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 회원가입 시 등록한 사항에 변경이 있는 경우 상당한
                기간 이내에 애드파워에 대하여 회원정보 수정 등의 방법으로 그
                변경사항을 알려야 합니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제6조 (회원의 의무)</strong>
          </p>
          <ol>
            <li>
              <p>
                회원은 관련 법령, 이 약관의 규정, 이용 안내 등 애드파워가
                통지하는 사항을 준수하여야 합니다.
              </p>
            </li>
            <li>
              <p>회원은 다음 각 호의 행위를 하여서는 안 됩니다.</p>
            </li>
          </ol>
          <p className="marginleftSU">가. 신청 또는 변경 시 허위 내용의 등록</p>
          <p className="marginleftSU">나. 타인의 정보도용</p>
          <p className="marginleftSU">
            다. 사이트에 게시된 정보의 허가받지 않은 변경
          </p>
          <p className="marginleftSU">
            라. 애드파워가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는
            게시
          </p>
          <p className="marginleftSU">
            마. 애드파워와 제3자의 저작권 등 지식재산권에 대한 침해
          </p>
          <p className="marginleftSU">
            바. 애드파워와 제3자의 명예를 손상하거나 업무를 방해하는 행위
          </p>
          <p className="marginleftSU">
            사. 외설 또는 폭력적인 메시지∙화상∙음성 기타 공서양속에 반하는
            정보를 사이트에 공개 또는 게시하는 행위
          </p>
          <p className="marginleftSU">
            아. 애드파워의 동의 없이 영리를 목적으로 서비스를 사용하는 행위자.
            기타 관계 법령이나 애드파워에서 정한 규정에 위배되는 행위
          </p>
          <p className="marginleftSU">
            <br></br>
          </p>
          <p>
            <strong>제7조 (회원의 아이디 및 비밀번호에 대한 의무)</strong>
          </p>
          <ol>
            <li>
              <p>
                아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 소홀히
                하여 발생한 모든 민∙형사상의 책임은 회원 자신에게 있습니다.
              </p>
            </li>
            <li>
              <p>
                회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안
                됩니다.
              </p>
            </li>
            <li>
              <p>
                회원이 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고
                있음을 인지한 경우에는 즉시 비밀번호를 변경하고 애드파워에
                통보합니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제8조 (회원 탈퇴 및 자격 상실 등)</strong>
          </p>
          <ol>
            <li>
              <p>
                회원은 애드파워에 언제든지 탈퇴를 요청할 수 있으며, 애드파워는
                회원 탈퇴에 관한 규정에 따라 이를 처리합니다.
              </p>
            </li>
            <li>
              <p>
                회원이 다음 각 호의 사유에 해당하는 경우, 애드파워는 회원자격을
                제한 및 정지시킬 수 있습니다.
              </p>
            </li>
          </ol>
          <p className="marginleftSU">
            가. 가입신청 시에 허위 내용을 등록하거나 타인의 정보를 도용한 경우
          </p>
          <p className="marginleftSU">
            나. 애드파워가 제공하는 서비스의 원활한 운영을 방해하는 행위를
            하거나 시도한 경우
          </p>
          <p className="marginleftSU">
            다. 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등
            전자상거래 질서를 위협하는 경우
          </p>
          <p className="marginleftSU">
            라. 사이트를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
            반하는 행위를 하는 경우
          </p>
          <ol start="3">
            <li>
              애드파워가 회원 자격을 제한 및 정지시킨 후 동일한 행위가 2회 이상
              반복되거나 30일 이내에 그 사유가 시정되지 않은 경우 애드파워는
              회원자격을 상실시킬 수 있습니다.
            </li>
          </ol>
          <p>
            <strong>제9조 (애드파워의 의무)</strong>
          </p>
          <ol>
            <li>
              <p>
                애드파워는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를
                하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로
                서비스를 제공하는데 최선을 다하여야 합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 회원이 안전하게 서비스를 이용할 수 있도록 회원의
                개인정보 보호를 위한 보안시스템을 갖추어야 하며,
                개인정보취급방침을 공시하고 이를 준수합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 이용자가 원하지 않는 영리 목적의 광고성 전자우편 및
                문자 등을 발송하지 않습니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 회원으로부터 제기되는 의견이나 불만이 정당하다고
                객관적으로 인정될 경우에는 적절한 절차를 거쳐 처리하여야 합니다.
                다만, 처리가 곤란한 경우에는 회원에게 그 사유를 통보하여야
                합니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제10조 (서비스의 제공 및 변경)</strong>
          </p>
          <ol>
            <li>애드파워는 다음과 같은 서비스를 제공합니다.</li>
          </ol>
          <p className="marginleftSU">
            가. 애드파워가 운영하는 사이트에서 명시하는 서비스
          </p>
          <p className="marginleftSU">
            나. 기타 애드파워가 정하는 서비스 또는 업무
          </p>
          <p className="marginleftSU">
            <br></br>
          </p>
          <p>
            <strong>제11조 (서비스의 중단)</strong>
          </p>
          <ol>
            <li>
              <p>
                애드파워는 컴퓨터 등 정보통신설비의 보수 점검 및 교체, 고장,
                통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을
                일시적으로 중단할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                애드파워가 서비스 제공을 일시적으로 중단할 경우 서비스 일시 중단
                사실과 그 사유를 사이트 초기화면에 고지합니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제12조 (개인정보보호)</strong>
          </p>
          <ol>
            <li>
              <p>
                애드파워는 회원의 개인정보를 보호하기 위하여 『정보통신망
                이용촉진 및 정보보호 등에 관한 법률』 등 관계 법령에서 정하는
                바를 준수합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 회원의 개인정보를 보호하기 위하여 개인정보취급방침을
                제정, 서비스 초기 화면에 게시합니다. 다만, 개인정보취급방침의
                구체적 내용은 연결화면을 통하여 볼 수 있도록 합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 회원의 개인정보를 수집 및 이용하는 때에는 당해
                회원에게 그 목적을 고지하고 동의받으며 개인정보취급방침에 따라
                회원의 개인정보를 보호하기 위하여 노력합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며,
                새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는
                이용 및 제공단계에서 해당 회원에게 그 목적을 고지하고
                동의받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로
                합니다.
              </p>
            </li>
            <li>
              <p>
                회원은 언제든지 애드파워가 가지고 있는 자신의 개인정보에 대해
                열람 및 오류 정정을 요구할 수 있으며 애드파워는 이에 대해 지체
                없이 필요한 조치를 할 의무를 집니다.
              </p>
            </li>
            <li>
              <p>
                애드파워의 사이트 이외의 링크된 사이트에서는 애드파워의
                개인정보취급방침이 적용되지 않습니다. 링크된 사이트를 제공하는
                제3자의 개인정보 취급과 관련하여서는 해당 사이트 및 제3자의
                개인정보취급방침을 확인할 책임이 회원에게 있으며, 애드파워는
                이에 대하여 책임을 부담하지 않습니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제13조 (회원에 대한 통지)</strong>
          </p>
          <ol>
            <li>
              <p>
                애드파워가 회원에게 통지하는 경우, 회원의 알림 설정 여부에 따라
                회원가입 시 입력한 전자우편주소로 할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 불특정 다수 회원에 대한 통지의 경우 1주일 이상
                사이트의 공지사항 등에 게시함으로써 개별 통지에 갈음할 수
                있습니다.
              </p>
            </li>
          </ol>
          <h3>제2장 기타 사항</h3>
          <p>
            <strong>제14조 (연결 사이트와 피 연결 사이트의 관계)</strong>
          </p>
          <ol>
            <li>
              <p>
                상위 사이트와 하위 사이트가 하이퍼링크(예 : 하이퍼링크의
                대상에는 문자, 그림 및 동영상 등이 포함됨) 방식 등으로 연결된
                경우, 전자를 연결 사이트라고 하고, 후자를 피 연결 사이트라고
                합니다.
              </p>
            </li>
            <li>
              <p>
                연결 사이트는 피 연결 사이트가 독자적으로 이용자와 행하는 그
                어떠한 거래 및 행위에 대해서도 책임을 지지 않습니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제15조 (저작권의 귀속 및 이용 제한)</strong>
          </p>
          <ol>
            <li>
              <p>
                애드파워가 작성한 저작물에 대한 저작권 및 기타 지식재산권은
                애드파워에 귀속합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 서비스와 관련하여 회원에게 애드파워가 정한 이용조건에
                따라 계정, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며,
                회원은 서비스를 이용함으로써 얻은 정보 중 애드파워에
                지식재산권이 귀속된 정보를 애드파워의 사전 승낙 없이 복제, 송신,
                출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나
                제3자에게 이용하게 하여서는 안 됩니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>제16조 (회원의 게시물)</strong>
          </p>
          <ol>
            <li>
              회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한
              회원에게 있으며, 애드파워는 회원이 게시하거나 등록하는 서비스의
              내용물이 다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이
              삭제할 수 있고, 이에 대하여 애드파워는 어떠한 책임도 지지
              않습니다.
            </li>
          </ol>
          <p className="marginleftSU">
            가. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상하는
            내용인 경우
          </p>
          <p className="marginleftSU">나. 공서양속에 위반되는 내용일 경우</p>
          <p className="marginleftSU">
            다. 범죄적 행위에 결부된다고 인정되는 경우
          </p>
          <p className="marginleftSU">
            라. 애드파워의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인
            경우
          </p>
          <p className="marginleftSU">
            마. 회원이 사이트와 게시판에 음란물을 게재하거나 음란사이트를
            링크하는 경우
          </p>
          <p className="marginleftSU">
            바. 애드파워로부터 사전 승인받지 아니한 상업광고, 판촉 내용을
            게시하는 경우
          </p>
          <p className="marginleftSU">
            사. 기타 관련 법령에 위반된다고 판단되는 경우
          </p>
          <ol start="2">
            <li>
              회원 탈퇴 이후 회원이 작성하였던 게시물 및 댓글 등은 삭제되지
              않으며, 회원이 작성한 게시물의 삭제를 원할 경우에는 회원 탈퇴
              이전에 게시물을 모두 삭제하여야 합니다.
            </li>
          </ol>
          <p>
            <strong>제17조 (게시물의 관리)</strong>
          </p>
          <ol>
            <li>
              <p>
                회원의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한
                법률』, 『개인정보 보호법』 및 『저작권법』 등 관련 법령에
                위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에
                따라 해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며,
                애드파워는 관련 법령에 따라 조치하여야 합니다.
              </p>
            </li>
            <li>
              <p>
                애드파워는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
                인정될 만한 사유가 있거나 기타 애드파워 정책 및 관련 회칙에
                위반되는 경우에는 관련 회칙에 따라 해당 게시물에 대해 임시조치
                등을 취할 수 있습니다.
              </p>
            </li>
          </ol>
          <h3>부칙</h3>
          <ol>
            <li>이 약관은 2022년 12월 17일부터 시행합니다.</li>
          </ol>
          <br></br>
        </div>
      </div>

      <div className="checkbox-container">
        <input
          type={"checkbox"}
          id="chk_2"
          onChange={(e) => checkHandler(e)}
        ></input>
        <label htmlFor="chk_2">
          {Checked2 ? (
            <img
              src={ImageComponent.CheckBox_On}
              className="img_checkBox"
            ></img>
          ) : (
            <img
              src={ImageComponent.CheckBox_Off}
              className="img_checkBox"
            ></img>
          )}
        </label>
        <label htmlFor="chk_2">
          <span className="chk_2">개인정보 수집 및 이용 동의 (필수)</span>
        </label>
      </div>
      <div className="terms">
        <div>
          <p>
            개인정보 보호법에 따라 애드파워에 회원가입 신청을 하시는 분께
            수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의
            보유 및 이용 기간에 관한 사항을 안내해 드리오니 자세히 읽은 후
            동의하여 주시기 바랍니다.
          </p>
          <br></br>
          <p>
            <strong>1. 수집하는 개인정보</strong>
          </p>
          <br></br>
          <p>
            회원 가입 시 ‘이름, 부서, 기수, 아이디, 비밀번호, 이메일’을
            필수항목으로 수집합니다.
          </p>
          <p>
            <br></br>
          </p>
          <p>
            <strong>2. 수집한 개인정보의 이용</strong>
          </p>
          <br></br>
          <p>
            애드파워의 회원 관리, 서비스 개발・제공 및 향상, 안전한 인터넷
            이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.
          </p>
          <ol>
            <li>
              <p>
                회원 가입 의사의 확인, 애드파워 소속 여부 확인, 이용자 식별,
                회원 정보 찾기, 회원 탈퇴 의사의 확인 등 회원 관리를 위하여
                개인정보를 이용합니다.
              </p>
            </li>
            <li>
              <p>
                보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는
                서비스 이용환경 구축을 위해 개인정보를 이용합니다.
              </p>
            </li>
          </ol>
          <p>
            <strong>3. 개인정보의 보관기간</strong>
          </p>
          <br></br>
          <p>이용자의 개인정보는 회원 탈퇴 요청 시 바로 파기됩니다.</p>
        </div>
      </div>

      <div className="check-buttons">
        <Link to="/">
          <button>취소</button>
        </Link>
        <button
          className="btn-write"
          onClick={() => {
            modalHandler();
          }}
        >
          확인
        </button>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={1}
        content={contentid}
      />
    </div>
  );
}
