import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import { lnbIdList } from "../common/lnbID";
import PagingComponent from "../post/PagingComponent";
import "./ForumComponent.css";
import ConvertForumDateStyle from "./ConvertForumDateStyle";

function ForumGridComponent() {
    const nowParams = useParams();
    // 게시글 있는지 없는지

    //현재 페이지 설정 쿼리스트링
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("search");
    const currentPage = searchParams.get("currentPage");

    //데이터 가져오기
    const [posts, setPosts] = useState([]);

    //페이지네이션
    const [totalPage, setTotalPage] = useState([[]]);

    useEffect(() => {
        axios
            .get(
                process.env.REACT_APP_API_HOST +
                    "/api/posts/pagination/" +
                    lnbIdList[nowParams.Sub],
                {
                    params: {
                        search: search,
                        page: currentPage,
                        perPage: 9,
                    },
                }
            )
            .then((response) => {
                setPosts(response.data.data.posts);

                const pageArray = Array.from(
                    { length: response.data.data.totalPage },
                    (v, i) => i + 1
                );
                const pageArr = [];
                for (let i = 0; i < pageArray.length; i += 10) {
                    pageArr.push(pageArray.slice(i, i + 10));
                }

                // 페이지 나누기..
                if (pageArr.length !== 0) {
                    setTotalPage(pageArr);
                } else {
                    setTotalPage([[]]);
                }
            });
    }, [nowParams, currentPage]);

    //게시글 하나하나 => 카테고리별 게시글로 api수정
    const fl = posts.map((post) => {
        return (
            <div
                className={
                    post.post_no == nowParams.id
                        ? "grid-container now_post shadow"
                        : "grid-container shadow"
                }
                key={post.post_no}
            >
                <Link
                    to={`post/?postNo=${post.post_no}&search=${
                        search || ""
                    }&currentPage=${currentPage}`}
                >
                    {post.post_is_important ? (
                        <p className="grid-star">★</p>
                    ) : null}
                    <img src={post.post_thumb1 || ImageComponent.default}></img>
                    <div className="grid-contents">
                        <p className="forum-title clamp1">{post.title}</p>
                        <p className="forum-card-info">
                            <span className="forum-name clamp1">
                                {post.user_nick}
                            </span>
                            <span className="forum-date clamp1">
                                {ConvertForumDateStyle(post.post_ins_date)}
                            </span>
                        </p>
                    </div>
                </Link>
            </div>
        );
    });

    return (
        <div className="forum-main">
            <div className="forum-grid">
                {posts.length != 0 ? (
                    fl
                ) : (
                    <p className="no_data">등록된 게시글이 없습니다.</p>
                )}
            </div>

            <div className="forum-bottom">
                <Link to="editor">
                    <button className="btn-write">글쓰기</button>
                </Link>
            </div>
            {totalPage[0].length > 0 ? (
                <PagingComponent
                    currentPage={currentPage}
                    totalPage={totalPage}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                ></PagingComponent>
            ) : null}
        </div>
    );
}

export default ForumGridComponent;
