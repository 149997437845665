// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../../Modal/Modal";
import PagingComponent from "../../post/PagingComponent";
import { useSearchParams } from "react-router-dom";
import CalendarComponent from "../../dropdown/CalendarComponent";

export default function AdminCalendarComponent() {
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("등록");
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  //페이지네이션
  const [totalPage, setTotalPage] = useState([[]]);

  // 캘린더
  const [calendarTitle, setCalendarTitle] = useState();
  const [calendarDate, setCalendarDate] = useState();
  const [calendarStart, setCalendarStart] = useState();
  const [calendarEnd, setCalendarEnd] = useState();
  const [eventId, setEventId] = useState();

  const handleTitleValue = (e) => {
    setCalendarTitle(e.target.value);
  };
  const handleDateValue = (e) => {
    setCalendarDate(e.target.value);
  };
  const handleStartValue = (e) => {
    setCalendarStart(e.target.value);
  };
  const handleEndValue = (e) => {
    setCalendarEnd(e.target.value);
  };

  useEffect(() => {
    setCalendarTitle("");
    setCalendarStart("");
    setCalendarEnd("");
  }, [status]);

  // 달력 삽입, 업데이트
  const onClickInsert = (e) => {
    setModal(true);
    setModalType(2);
    if (e.target.textContent === "등록") {
      setContentID(13);
      setModalEvent(() => {
        const inserts = () => {
          insertEvent();
        };
        return inserts;
      });
    } else {
      // 업데이트
      setContentID(12);
      setModalEvent(() => {
        const updates = () => {
          updateEvent();
        };
        return updates;
      });
    }
  };

  const insertEvent = () => {
    if (calendarTitle && calendarDate) {
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/calendars/insert", {
          param: {
            cal_title: calendarTitle,
            cal_date: calendarDate,
            cal_start_time: calendarStart,
            cal_end_time: calendarEnd,
          },
        })
        .then((response) => {
          setCalendarTitle("");
          setCalendarStart("");
          setCalendarEnd("");
          setModal(true);
          setModalType(1);
          setContentID(28);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  // 달력 삭제
  const onClickDelete = () => {
    setModal(true);
    setModalType(2);
    setContentID(11);
    setModalEvent(() => {
      const deletes = () => {
        deleteEvent();
      };
      return deletes;
    });
  };

  const deleteEvent = () => {
    if (eventId) {
      axios
        .delete(
          process.env.REACT_APP_API_HOST + `/api/calendars/delete/${eventId}`
        )
        .then((response) => {
          setModal(true);
          setModalType(1);
          setContentID(26);
          setModalEvent(() => {
            const modalFalse = () => {
              setModal(false);
              setStatus(!status);
            };
            return modalFalse;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // 달력 업데이트
  const updateEvent = () => {
    axios
      .put(process.env.REACT_APP_API_HOST + `/api/calendars/update`, {
        param: [
          {
            cal_title: calendarTitle,
            cal_date: calendarDate,
            cal_start_time: calendarStart,
            cal_end_time: calendarEnd,
          },
          eventId,
        ],
      })
      .then(() => {
        setModal(true);
        setModalType(1);
        setContentID(27);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">캘린더</div>
      <div className="admin-hearder--2 shadow">
        <CalendarComponent
          className={"admin-calendar shadow"}
          setCalendarTitle={setCalendarTitle}
          setCalendarDate={setCalendarDate}
          setCalendarStart={setCalendarStart}
          setCalendarEnd={setCalendarEnd}
          setEventId={setEventId}
          setType={setType}
        />
        <div className="admin-calendar-container">
          <span className="admin-calendar-title">캘린더 일정 {type}</span>
          <hr />
          <div>
            <label className="admin-calendar-label">일정</label>
            <input
              className="admin-calendar-input"
              value={calendarTitle}
              onChange={(e) => {
                handleTitleValue(e);
              }}
            ></input>
          </div>
          <div>
            <label className="admin-calendar-label">날짜</label>
            <input
              className="admin-calendar-input"
              value={calendarDate}
              onChange={(e) => {
                handleDateValue(e);
              }}
            ></input>
          </div>
          <div>
            <label className="admin-calendar-label">시작 시간</label>
            <input
              className="admin-calendar-input"
              placeholder="12:00"
              value={calendarStart}
              onChange={(e) => {
                handleStartValue(e);
              }}
            ></input>
          </div>
          <div>
            <label className="admin-calendar-label">종료 시간</label>
            <input
              className="admin-calendar-input"
              placeholder="15:00"
              value={calendarEnd}
              onChange={(e) => {
                handleEndValue(e);
              }}
            ></input>
          </div>
          <button
            className="btn-write"
            onClick={(e) => {
              onClickInsert(e);
            }}
          >
            {type === "등록" ? "등록" : "수정"}
          </button>
          <button
            className="btn-write"
            onClick={() => {
              onClickDelete();
            }}
            style={{ display: eventId ? "block" : "none" }}
          >
            삭제
          </button>
        </div>
      </div>
      {/* {totalPage[0].length > 0 ? (
        <PagingComponent
          currentPage={currentPage}
          totalPage={totalPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        ></PagingComponent>
      ) : null} */}

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
