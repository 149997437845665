import React from "react";
import "./Modal.css";

const Modal = (props) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, type, content, modalEvent } = props;
  const contents = {
    1: {
      //회원가입
      1: [
        "애드파워 이용약관에 동의하셔야",
        <br />,
        "회원가입 하실 수 있습니다.",
      ],
      2: [
        "개인정보 수집 및 이용에 동의하셔야",
        <br />,
        "회원가입 하실 수 있습니다.",
      ],
      3: ["회원가입이 완료되었습니다.", <br />, "회원가입 하실 수 있습니다."],
      4: ["정보를 모두 입력해주십시오."],

      //글쓰기
      5: ["임시저장한 글이 삭제되었습니다."],
      6: ["작성 취소되었습니다."],
      7: ["제목을 입력해 주세요."],
      "7-1": ["제목은 100자 이내로 작성해주세요."],
      8: ["내용을 입력해 주세요."],
      "8-1": ["작성할 수 있는 내용 길이를 초과했습니다."],

      //댓글
      9: ["댓글을 입력해 주세요."],
      10: ["댓글이 삭제되었습니다."],

      //신입모집
      11: ["지원 방식 안내서는 ", <br />, "신입 모집 기간에 오픈됩니다."],
      12: ["면접 안내서는 추후 공개됩니다."],
      13: ["최종 합격자는 추후 공개됩니다."],

      //게시글 수정
      14: ["게시글이 삭제되었습니다."],

      //권한
      15: ["접근 권한이 없습니다."],
      16: ["등록 권한이 없습니다."],
      17: ["비밀번호가 틀렸습니다."],

      // 임시저장
      18: ["임시저장이 완료되었습니다."],
      19: ["말머리, 제목, 내용을 모두 입력해주세요."],
      20: ["삭제 되었습니다."],

      // 찾기
      21: ["해당 이메일로 아이디 정보를 전송했습니다."],
      22: ["해당 이메일로 등록된 회원 정보가 없습니다."],
      23: ["해당 이메일로 임시 비밀번호를 전송했습니다."],
      24: ["보안 인증을 포함한", <br />, "모든 내용을 입력해주세요."],

      // 회원탈퇴
      25: ["탈퇴되었습니다."],

      // admin
      26: ["삭제되었습니다."],
      27: ["수정되었습니다."],
      28: ["등록되었습니다."],
      29: ["중복된 데이터입니다"],
    },
    2: {
      //회원가입
      1: ["정말 취소하시겠습니까?"],

      //글쓰기
      2: [
        "임시저장된 글을 불러오면,",
        <br />,
        "작성중인 글은 사라집니다.",
        <br />,
        "임시저장 글을 불러오시겠습니까?",
      ],
      3: [
        "삭제한 글은 복구할 수 없습니다.",
        <br />,
        "임시저장된 글을 삭제하시겠습니까?",
      ],
      4: [
        "작성 취소한 글은 복구할 수 없습니다.",
        <br />,
        "작성하던 글을 삭제하시겠습니까?",
      ],
      5: [
        "삭제한 댓글은 복구할 수 없습니다.",
        <br />,
        "정말로 댓글을 삭제하시겠습니까?",
      ],
      6: [
        "삭제한 글은 복구할 수 없습니다.",
        <br />,
        "해당 게시글을 삭제하시겠습니까?",
      ],

      //임시저장
      7: [
        "삭제한 임시글은 복구할 수 없습니다.",
        <br />,
        "해당 임시 게시글 삭제하시겠습니까?",
      ],
      8: [
        "삭제한 임시글은 복구할 수 없습니다.",
        <br />,
        "임시 게시글을 모두 삭제하시겠습니까?",
      ],
      9: [
        "작성중인 게시글은 복구할 수 없습니다.",
        <br />,
        "임시 저장된 게시글로 덮어쓰시겠습니까?",
      ],

      // 회원탈퇴
      10: ["정말 탈퇴하시겠습니까?"],

      // Admin
      11: ["삭제하시겠습니까?"],
      12: ["수정하시겠습니까?"],
      13: ["등록하시겠습니까?"],
    },
  };

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? "openModal modal" : "modal"}>
      {open ? (
        <section className="shadow">
          <main>{contents[type][content] || content}</main>
          {type === 1 ? (
            <div className="modal-footer">
              <button className="yes" onClick={modalEvent || close}>
                확인
              </button>
            </div>
          ) : (
            <div className="modal-footer">
              <button className="no" onClick={close}>
                아니요
              </button>
              <button className="yes" onClick={modalEvent}>
                예
              </button>
            </div>
          )}
        </section>
      ) : null}
    </div>
  );
};

export default Modal;
