import React, { useEffect, useState } from "react";
import "./DraftComponent.css";
import ImageComponent from "../common/ImageComponent";
import axios from "axios";
import { lnbIdList } from "../common/lnbID";
import { useParams } from "react-router-dom";
import store from "../../store";
import Modal from "../Modal/Modal";

export default function DraftComponent({ setDraft, onClickTemp, onClickLoad }) {
  const nowParams = useParams();
  const { userStore } = store;
  const [data, setData] = useState([]);
  const [update, SetUpdate] = useState(false);

  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          `/api/posts/findtemppost?cat_no=${lnbIdList[nowParams.Sub]}&user_no=${
            userStore.checkStatus().userNo
          }`
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [update]);

  const onClickDelete = (postNo) => {
    setModal(!modal);
    setModalType(2);
    setContentID(7);
    setModalEvent(() => {
      const deletes = () => {
        executeDelete(postNo);
      };
      return deletes;
    });
  };

  const executeDelete = (postNo) => {
    axios
      .delete(process.env.REACT_APP_API_HOST + `/api/posts/delete/${postNo}`)
      .then((response) => {
        SetUpdate(!update);
        setModal(false);
        setModalType(1);
        setContentID(20);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 전체삭제
  const onClickDeleteAll = (data) => {
    let list = "";
    data.forEach((element, i) => {
      list += `${element.post_no}${i !== data.length - 1 ? "," : ""}`;
    });
    setModal(!modal);
    setModalType(2);
    setContentID(8);
    setModalEvent(() => {
      const deletes = () => {
        executeDeleteAll(list);
      };
      return deletes;
    });
  };

  const executeDeleteAll = (list) => {
    axios
      .delete(process.env.REACT_APP_API_HOST + `/api/posts/deleteall`, {
        data: {
          param: {
            user_no: userStore.checkStatus().userNo,
            cat_no: lnbIdList[nowParams.Sub],
          },
        },
      })
      .then((response) => {
        SetUpdate(!update);
        setModal(false);
        setModalType(1);
        setContentID(20);
        setDraft(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //
  const onClickTempModal = (item) => {
    setModal(!modal);
    setModalType(2);
    setContentID(9);
    setModalEvent(() => {
      const click = () => {
        onClickLoad(item);
        setModal(false);
        setDraft(false);
      };
      return click;
    });
  };

  const draftList = data.map((item) => {
    return (
      <div
        className="draft-content"
        onClick={() => {
          onClickTempModal(item);
        }}
      >
        <p className="draft-title clamp1">{item.title}</p>
        <span className="draft-date">{item.post_ins_date}</span>
        <span
          className="draft-delete"
          onClick={(e) => {
            onClickDelete(item.post_no);
            e.stopPropagation();
          }}
        >
          삭제
        </span>
      </div>
    );
  });

  return (
    <div className="draft shadow">
      <div className="draft-top">
        <span className="draft-head">임시저장된 글</span>
        <span
          className="draft-btn"
          onClick={() => {
            onClickTemp();
          }}
        >
          임시저장하기
        </span>
        <img
          src={ImageComponent.Close}
          className="img_close"
          onClick={() => {
            setDraft(false);
          }}
        ></img>
      </div>
      <div className="draft-list">
        <div className="draft-list-head">
          <span>총 </span>
          <span className="draft-count"> {data.length}</span>
          <span>개</span>
          <span className="draft-deleteall" onClick={() => onClickDeleteAll(data)}>
            전체삭제
          </span>
        </div>

        <hr />

        {draftList}
      </div>
      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
