import React, { useState } from "react";
import ImageComponent from "../../common/ImageComponent";

export default function AdminUserTableComponent({ users }) {
  const selectAll = (e) => {
    const checkboxes = document.getElementsByClassName("chk_imp_user");
    Array.from(checkboxes).forEach((checkbox) => {
      checkbox.checked = e.target.checked;
    });
  };
  const userTable = users.map((user) => {
    return (
      <>
        <div className="admin-user" key={user.user_no}>
          <input
            type={"checkbox"}
            id={`${user.user_no}`}
            className="chk_imp chk_imp_user"
          ></input>
          <label htmlFor={`${user.user_no}`}>
            <img src={ImageComponent.Check_Red} className="img_checkred"></img>
          </label>
          <div className="admin-user-nick-container">
            <input
              id={`user_nick${user.user_no}`}
              className="admin-user-input"
              defaultValue={user.user_nick}
            ></input>
          </div>
          <div className="admin-user-name-container">
            <input
              id={`user_name${user.user_no}`}
              className="admin-user-input"
              defaultValue={user.user_name}
            ></input>
          </div>
          <div className="admin-user-department-container">
            <input
              id={`user_department${user.user_no}`}
              className="admin-user-input"
              defaultValue={user.user_department}
            ></input>
          </div>
          <div className="admin-user-department-number-container">
            <input
              id={`user_department_number${user.user_no}`}
              className="admin-user-input"
              defaultValue={user.user_department_number}
            ></input>
          </div>
          <div className="admin-user-class-container">
            <input
              id={`user_class${user.user_no}`}
              className="admin-user-input"
              defaultValue={user.user_class}
            ></input>
          </div>
          <div className="admin-user-id-container">
            <input
              id={`user_id${user.user_no}`}
              className="admin-user-input"
              defaultValue={user.user_id}
            ></input>
          </div>
          <div className="admin-user-email-container">
            <input
              className="admin-user-input"
              defaultValue={user.user_email}
            ></input>
          </div>
        </div>
        <hr />
      </>
    );
  });

  return (
    <div className="admin-user-table shadow">
      <div className="admin-user-table-inner">
        <div className="admin-user-table--header">
          <input
            type={"checkbox"}
            id={`admin-user-table-checkbox`}
            className="chk_imp"
            onClick={(e) => selectAll(e)}
          ></input>
          <label htmlFor={`admin-user-table-checkbox`}>
            <img src={ImageComponent.Check_Red} className="img_checkred"></img>
          </label>
          <div className="admin-user-nick-container">
            <span>&nbsp; 닉네임</span>
          </div>
          <div className="admin-user-name-container">
            <span>&nbsp; 이름</span>
          </div>
          <div className="admin-user-department-container">
            <span>&nbsp; 부서</span>
          </div>
          <div className="admin-user-department-number-container">
            <span>기수</span>
          </div>
          <div className="admin-user-class-container">
            <span>권한</span>
          </div>
          <div className="admin-user-email-container">
            <span>아이디</span>
          </div>
          <div className="admin-user-email-container">
            <span>&nbsp; 이메일</span>
          </div>
        </div>
        <hr />
        {userTable}
      </div>
    </div>
  );
}
