import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import store from "../../store";
import Modal from "../Modal/Modal";
import axios from "axios";
import getAuthorization from "./getAuthorization";
import NullPage from "../pages/NullPage";

/**
 * 목록, 글읽기 권한
 */
function ViewRoute({ component, content }) {
  const navigate = useNavigate();
  const nowParams = useParams(); //주소에서 /:Category/:Sub

  const { userStore } = store;

  const [isLoading, setIsLoading] = useState(true); // 정보를 가져올 때 로딩 상태를 보여주기 위한 state

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    getAuthorization(token, setIsLoading);
  }, [userStore, nowParams]);

  // isLoading이 true일 동안 로딩 중이라는 화면을 보여줍니다.
  if (isLoading) {
    return <NullPage />;
  }

  if (
    nowParams.Category === "커뮤니티" ||
    nowParams.Category === "부서" ||
    nowParams.Category === "운영팀" ||
    ["집회 리뷰", "전체스터디 리뷰", "경쟁 PT", "기타"].includes(nowParams.Sub)
  ) {
    // 유저클래스 2이상 접근가능
    if (userStore.userClass >= 1) {
      return component;
    }
  } else if (nowParams.Sub === "운영 DB") {
    // 유저클래스 5이상 접근가능
    if (userStore.userClass >= 4) {
      return component;
    }
  } else {
    // 비로그인 + 123456
    return component;
  }

  //조건문 안걸리면 해당 안되는거
  return (
    <Modal
      open={true}
      type={1}
      content={content}
      modalEvent={() => {
        navigate(-1);
      }}
    />
  );
}

export default ViewRoute;
