// import Slick
import React, { useEffect, useState } from "react";
import ImageComponent from "../common/ImageComponent";
import { useNavigate } from "react-router-dom";

export default function AdminMenuComponent() {
  const navigate = useNavigate();
  const [sidebarTop, setSidebarTop] = useState(30);
  useEffect(() => {
    const handleScroll = () => {
      const top = Math.max(20, window.pageYOffset + 30);
      // setSidebarTop(top);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="admin-menu">
      <div id="admin-menu-contents" style={{ top: `${sidebarTop}px` }}>
        <img
          src={ImageComponent.Logo}
          onClick={() => {
            navigate("/");
          }}
        ></img>
        <p>관리자 페이지</p>
        <hr />
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Users?search=&currentPage=1");
          }}
        >
          회원관리
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Calendar");
          }}
        >
          캘린더
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Brand");
          }}
        >
          브랜드
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Youtube");
          }}
        >
          유튜브
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Popup");
          }}
        >
          팝업
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Header");
          }}
        >
          말머리
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Intro");
          }}
        >
          소개
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/BI");
          }}
        >
          BI
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/History");
          }}
        >
          연혁
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/People");
          }}
        >
          운영위원회
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Rules");
          }}
        >
          회칙
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Recruit");
          }}
        >
          모집 요강
        </div>
        <div
          className="admin-menu-item"
          onClick={() => {
            navigate("/Admin/Notice");
          }}
        >
          모집 공지사항
        </div>
      </div>
    </div>
  );
}
