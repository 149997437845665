import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./BrandSliderComponent.css";

export default function SliderComponent() {
  const scrollRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();

  const onDragStart = (e) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e) => {
    if (isDrag) {
      scrollRef.current.scrollLeft = startX - e.pageX;
    }
  };

  const [brands, setBrands] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/mainPages/3")
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div
      className="brands"
      onMouseDown={onDragStart}
      onMouseMove={onDragMove}
      // onMouseMove={isDrag ? onThrottleDragMove : null}
      onMouseUp={onDragEnd}
      onMouseLeave={onDragEnd}
      ref={scrollRef}
    >
      {brands.map((brand, i) => (
        <div className="brand" key={brand.brand_name}>
          <p className="brand-year">{brand.brand_when}</p>
          <div className="center-line">
            <div className="circle"></div>
            <hr className={i+1 === brands.length ? 'hidden' : undefined}></hr>
          </div>
          <p className="brand-name">{brand.brand_name}</p>
        </div>
      ))}
    </div>
  );
}
