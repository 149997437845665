import axios from "axios";
import { React, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import Modal from "../Modal/Modal";
import "./RecruitComponent.css";

export default function RecruitComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");
  const [changeStep, setChangeStep] = useState(false);
  const nowParams = useParams();

  const [recruitList, SetRecruitList] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/adpowerInfo/recruit")
      .then((response) => {
        SetRecruitList(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const nameList = ["지원 방식 안내서", "면접 안내서", "최종 합격자 발표"];

  // 다운로드
  const getFile = (url) => {
    // loadingStore.setIstLoading(true);
    fetch(url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = nameList[changeStep];
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
    // .finally(() => loadingStore.setIstLoading(false));
  };

  const [modal, setModal] = useState(false);
  const [contentid, setContentId] = useState();

  const Recruit = recruitList.map((exh) => {
    return (
      <div
        className="card-gallery"
        onClick={() => {
          if (exh.ap_open !== 1) {
            setModal(true);
            setContentId(exh.ap_note * 1 + 11);
          } else {
            setChangeStep(exh.ap_note);
          }
        }}
      >
        <img
          src={
            exh.ap_open === 1
              ? ImageComponent.Interview_On
              : ImageComponent.Interview_Off
          }
        ></img>
        <span className="recruit-card-name">{nameList[exh.ap_note]}</span>
      </div>
    );
  });

  return (
    <>
      {!changeStep ? (
        <div className="recruit">{Recruit} </div>
      ) : (
        <div className="recruit-container">
          <img
            className="recruit-img"
            src={recruitList[changeStep].ap_image_url}
          ></img>
          {recruitList.length !== 0 && recruitList[changeStep]?.ap_file_url ? (
            <div className="info-container">
              <button
                onClick={() => {
                  getFile(recruitList[changeStep]?.ap_file_url);
                }}
              >
                {nameList[changeStep]} 다운로드
              </button>
            </div>
          ) : null}
        </div>
      )}

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={1}
        content={contentid}
      />
    </>
  );
}

