// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import AdminUserTableComponent from "./AdminUserTableComponent";
import Modal from "../../Modal/Modal";
import PagingComponent from "../../post/PagingComponent";
import { useSearchParams } from "react-router-dom";

export default function AdminUserComponent() {
  const [filter, setFilter] = useState(0);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get("currentPage");
  const searchValue = searchParams.get("search");

  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  //페이지네이션
  const [totalPage, setTotalPage] = useState([[]]);

  const handleRadio = (e) => {
    setFilter(e.target.value);
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    onClickSearch();
    const checkboxes = document.getElementsByClassName("chk_imp");
    Array.from(checkboxes).forEach((checkbox) => {
      checkbox.checked = false;
    });
  }, [status]);

  useEffect(() => {
    onClickSearch();
  }, [searchParams]);

  const onClickSearch = () => {
    axios
      .get(process.env.REACT_APP_API_HOST + `/api/admins/pagination/user/`, {
        params: {
          filter: filter,
          search: searchValue,
          page: currentPage,
          perPage: 20,
        },
      })
      .then((response) => {
        setUsers(response.data.data.users);
        const pageArray = Array.from(
          { length: response.data.data.totalPage },
          (v, i) => i + 1
        );
        const pageArr = [];
        for (let i = 0; i < pageArray.length; i += 5) {
          pageArr.push(pageArray.slice(i, i + 5));
        }

        // 페이지 나누기..
        if (pageArr.length !== 0) {
          setTotalPage(pageArr);
        } else {
          setTotalPage([[]]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClickDelete = () => {
    setModal(true);
    setModalType(2);
    setContentID(11);
    setModalEvent(() => {
      const deletes = () => {
        deleteUsers();
      };
      return deletes;
    });
  };

  const deleteUsers = () => {
    const checkboxList = document.getElementsByClassName("chk_imp");
    const checked = [];
    Array.prototype.forEach.call(checkboxList, function (checkbox) {
      if (checkbox.checked) checked.push(parseInt(checkbox.id));
    });
    axios
      .delete(process.env.REACT_APP_API_HOST + `/api/admins/delete/users`, {
        data: {
          param: checked,
        },
      })
      .then((response) => {
        setModal(true);
        setModalType(1);
        setContentID(26);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onClickUpdate = () => {
    setModal(true);
    setModalType(2);
    setContentID(12);
    setModalEvent(() => {
      const update = () => {
        updateUsers();
      };
      return update;
    });
  };
  const updateUsers = () => {
    const checkboxList = document.getElementsByClassName("chk_imp_user");
    const checked = [];
    Array.prototype.forEach.call(checkboxList, function (checkbox) {
      if (checkbox.checked) checked.push(parseInt(checkbox.id));
    });
    const param = [];

    checked.forEach((user_no) => {
      const userNick = document.getElementById(`user_nick${user_no}`).value;
      const userName = document.getElementById(`user_name${user_no}`).value;
      const userDepartment = document.getElementById(
        `user_department${user_no}`
      ).value;
      const userDepartmentNumber = document.getElementById(
        `user_department_number${user_no}`
      ).value;
      const userClass = document.getElementById(`user_class${user_no}`).value;

      param.push([
        {
          user_nick: userNick,
          user_name: userName,
          user_department: userDepartment,
          user_department_number: userDepartmentNumber,
          user_class: userClass,
        },
        user_no,
      ]);
    });

    axios
      .put(process.env.REACT_APP_API_HOST + `/api/admins/update/users`, {
        param: param,
      })
      .then(() => {
        setModal(true);
        setModalType(1);
        setContentID(27);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">회원관리</div>
      <div className="admin-user-header shadow">
        <div className="admin-user-header-left">
          <div className="admin-user-header-radio">
            <input
              type="radio"
              name="filter"
              value={0}
              checked={filter == 0 ? true : false}
              onChange={(e) => {
                handleRadio(e);
              }}
            ></input>
            <span>전체 검색</span>
            <input
              type="radio"
              name="filter"
              value={1}
              onChange={(e) => {
                handleRadio(e);
              }}
            ></input>
            <span>이름</span>
            <input
              type="radio"
              name="filter"
              value={2}
              onChange={(e) => {
                handleRadio(e);
              }}
            ></input>
            <span>부서</span>
            <input
              type="radio"
              name="filter"
              value={3}
              onChange={(e) => {
                handleRadio(e);
              }}
            ></input>
            <span>기수</span>
            <input
              type="radio"
              name="filter"
              value={4}
              onChange={(e) => {
                handleRadio(e);
              }}
            ></input>
            <span>권한</span>
          </div>
          <div className="admin-user-header-input">
            <input
              placeholder="검색어 입력"
              value={search}
              onChange={(e) => {
                onChangeSearch(e);
              }}
            ></input>
          </div>
        </div>
        <div className="admin-user-header-right">
          <button
            onClick={() => {
              searchParams.set("search", search);
              searchParams.set("currentPage", 1);
              setSearchParams(searchParams);
              onClickSearch();
            }}
          >
            찾기
          </button>
          <button
            onClick={() => {
              onClickUpdate();
            }}
          >
            수정
          </button>
          <button
            onClick={() => {
              onClickDelete();
            }}
          >
            삭제
          </button>
        </div>
      </div>
      <AdminUserTableComponent users={users} />

      {totalPage[0].length > 0 ? (
        <PagingComponent
          currentPage={currentPage}
          totalPage={totalPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        ></PagingComponent>
      ) : null}

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
