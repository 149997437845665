import axios from "axios";
import store from "../../store";
import setAuthorizationToken from "./setAuthorizationToken";
//로그인 이후 토큰 받는거
const getAuthorization = (token, setIsLoading) => {
  setAuthorizationToken(token);
  const { userStore } = store;
  axios
    .get(process.env.REACT_APP_API_HOST + "/api/users/auth")
    .then((response) => {
      if (response.data.ok === "ok") {
        userStore.setLoggedInfo(response.data.data);
      }
    })
    .catch((error) => {
      // console.log(error);
      userStore.setLogout();
      localStorage.removeItem("jwtToken");
    })
    .finally(() => {
      setIsLoading(false); // 로딩 상태 종료
    });
};

export default getAuthorization;
