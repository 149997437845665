import axios from "axios";
import { reaction } from "mobx";
import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import { getLnbName } from "../common/lnbID";
import CardDepartmentComponent from "./CardDepartmentComponent";
import "./MainComponent.css";
import { observer } from "mobx-react";
import store from "../../store";

function MainOneComponent() {
  const { userStore } = store;
  const [data, setData] = useState([]);
  useLayoutEffect(() => {
    if (userStore.isLogin)
      axios
        .get(process.env.REACT_APP_API_HOST + "/api/mainpages/1/1")
        .then((response) => {
          setData(response.data.data[0]);
        });
  }, [userStore.isLogin]);

  const mainByLogin = () => {
    if (userStore.isLogin) {
      return (
        //로그인 했을때
        <div className="main1-pi">
          <div className="main1-post">
            <p className="main1-post-week">
              {data && data.post_ins_date?.slice(0, 10)}
            </p>
            <p className="main1-post-title clamp2">{data && data.title}</p>
            <Link
              to={`/${getLnbName(data && data.cat_parent_no)}/${
                data && data.cat_name
              }/post/?postNo=${data && data.post_no}&search=&currentPage=1`}
            >
              <button className="main1-post-go">보러가기</button>
            </Link>
          </div>
          <div className="main1-post-img">
            <img
              src={(data && data.post_thumb1) || ImageComponent.Gym}
              className="main1-img-img"
            ></img>
          </div>
        </div>
      );
    } else {
      return (
        // 로그인 안했을때
        <div className="main1-pi">
          <div className="main1-post-img">
            <img src={ImageComponent.Gym} className="main1-img-img" />
          </div>
          <div className="main1-post main1-not-login">
            <p className="main1-post-slogan">Advertising has the power</p>
            <p className="main1-post-title1 clamp1">광고에는 힘이 있다</p>
            <p className="main1-post-title2 clamp1">애드파워</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="main1">
      {mainByLogin()}

      <CardDepartmentComponent />
    </div>
  );
}

export default observer(MainOneComponent);
