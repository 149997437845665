const lnbIdList = {
  애드파워: 1,
  커뮤니티: 2,
  부서: 3,
  운영팀: 4,
  아카이빙: 5,
  포트폴리오: 6,
  "신입 모집": 7,
  소개: 8,
  BI: 9,
  연혁: 10,
  운영위원회: 11,
  회칙: 12,
  보도자료: 13,
  공지사항: 14,
  "자유 게시판": 15,
  "홍보 게시판": 16,
  "안부 게시판": 17,
  "후원회 게시판": 18,
  기획부: 19,
  카피부: 20,
  디자인부: 21,
  영상부: 22,
  교육팀: 23,
  대외협력팀: 24,
  편집팀: 25,
  콘텐츠팀: 26,
  "집회 리뷰": 27,
  "전체스터디 리뷰": 28,
  "경쟁 PT": 29,
  전시회: 30,
  기타: 31,
  "운영 DB": 32,
  프로젝트: 33,
  애카이브: 34,
  PBA: 35,
  "모집 요강": 36,
  "모집 공지사항": 37,
  "Q&A": 38,
  "EX-HOMEPAGE": 41, // 추가됨
};

function getLnbName(id) {
  return Object.keys(lnbIdList).find((key) => lnbIdList[key] === id);
}

export { lnbIdList, getLnbName };

// [{"cat_no":1,"cat_name":"애드파워","cat_parent_no":null,"cat_order":0},
// {"cat_no":2,"cat_name":"커뮤니티","cat_parent_no":null,"cat_order":1},
// {"cat_no":3,"cat_name":"부서","cat_parent_no":null,"cat_order":2},
// {"cat_no":4,"cat_name":"운영팀","cat_parent_no":null,"cat_order":3},
// {"cat_no":5,"cat_name":"아카이빙","cat_parent_no":null,"cat_order":4},
// {"cat_no":6,"cat_name":"포트폴리오","cat_parent_no":null,"cat_order":5},
// {"cat_no":7,"cat_name":"신입 모집","cat_parent_no":null,"cat_order":6},
// {"cat_no":8,"cat_name":"소개","cat_parent_no":1,"cat_order":0},
// {"cat_no":9,"cat_name":"BI","cat_parent_no":1,"cat_order":1},
// {"cat_no":10,"cat_name":"연혁","cat_parent_no":1,"cat_order":2},
// {"cat_no":11,"cat_name":"운영위원회","cat_parent_no":1,"cat_order":3},
// {"cat_no":12,"cat_name":"회칙","cat_parent_no":1,"cat_order":4},
// {"cat_no":13,"cat_name":"보도자료","cat_parent_no":1,"cat_order":5},
// {"cat_no":14,"cat_name":"공지사항","cat_parent_no":2,"cat_order":0},
// {"cat_no":15,"cat_name":"자유 게시판","cat_parent_no":2,"cat_order":1},
// {"cat_no":16,"cat_name":"홍보 게시판","cat_parent_no":2,"cat_order":2},
// {"cat_no":17,"cat_name":"안부 게시판","cat_parent_no":2,"cat_order":3},
// {"cat_no":18,"cat_name":"후원회 게시판","cat_parent_no":2,"cat_order":4},
// {"cat_no":19,"cat_name":"기획부","cat_parent_no":3,"cat_order":0},
// {"cat_no":20,"cat_name":"카피부","cat_parent_no":3,"cat_order":1},
// {"cat_no":21,"cat_name":"디자인부","cat_parent_no":3,"cat_order":2},
// {"cat_no":22,"cat_name":"영상부","cat_parent_no":3,"cat_order":3},
// {"cat_no":23,"cat_name":"교육팀","cat_parent_no":4,"cat_order":0},
// {"cat_no":24,"cat_name":"대외협력팀","cat_parent_no":4,"cat_order":1},
// {"cat_no":25,"cat_name":"편집팀","cat_parent_no":4,"cat_order":2},
// {"cat_no":26,"cat_name":"콘텐츠팀","cat_parent_no":4,"cat_order":3},
// {"cat_no":27,"cat_name":"집회 리뷰","cat_parent_no":5,"cat_order":0},
// {"cat_no":28,"cat_name":"전체스터디 리뷰","cat_parent_no":5,"cat_order":1},
// {"cat_no":29,"cat_name":"경쟁 PT","cat_parent_no":5,"cat_order":2},
// {"cat_no":30,"cat_name":"전시회","cat_parent_no":5,"cat_order":3},
// {"cat_no":31,"cat_name":"기타","cat_parent_no":5,"cat_order":4},
// {"cat_no":32,"cat_name":"운영 DB","cat_parent_no":5,"cat_order":5},
// {"cat_no":33,"cat_name":"프로젝트","cat_parent_no":6,"cat_order":0},
// {"cat_no":34,"cat_name":"애카이브","cat_parent_no":6,"cat_order":1},
// {"cat_no":35,"cat_name":"PBA","cat_parent_no":6,"cat_order":2},
// {"cat_no":36,"cat_name":"모집 요강","cat_parent_no":7,"cat_order":0},
// {"cat_no":37,"cat_name":"모집 공지사항","cat_parent_no":7,"cat_order":1},
// {"cat_no":38,"cat_name":"Q&A","cat_parent_no":7,"cat_order":2}]
