import axios from "axios";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptcha({ className, setCaptcha, type }) {
  function onChange(value) {
    axios
      .post(process.env.REACT_APP_API_HOST + "/api/recaptcha/verify", {
        param: {
          value: value,
        },
      })
      .then((response) => {
        if (response.data.status === "ok") {
          setCaptcha(true);
        } else setCaptcha(false);
      })
      .catch(function (error) {
        console.log(error.config);
        setCaptcha(false);
      });
  }

  return (
    <ReCAPTCHA
      className={`recaptcha ${className}`}
      size={"normal"}
      sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
      onChange={onChange}
      key={type}
    />
  );
}
