import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import HeaderComponent from "./component/navigation/HeaderComponent";

import EditorPage from "./component/pages/EditorPage";
import FooterComponent from "./component/navigation/FooterComponent";
import PostPage from "./component/pages/PostPage";
import MainPage from "./component/pages/MainPage";
import SignupPage from "./component/pages/SignupPage";
import ForumPage from "./component/pages/ForumPage";
import GlobalNavBar from "./component/navigation/GlobalNavBarComponent";
import ScrollToTop from "./component/common/ScrollToTop";
import LoginPage from "./component/pages/LoginPage";
import MyPage from "./component/pages/MyPage";
import UserInfoPage from "./component/pages/UserInfoPage";

import NotFoundPage from "./component/pages/NotFoundPage";
import { observer } from "mobx-react";
import PrivateRoute from "./component/utils/PrivateRoute";
import PublicRoute from "./component/utils/PublicRoute";
import ViewRoute from "./component/utils/ViewRoute";
import PostRoute from "./component/utils/PostRoute";
import FindPage from "./component/pages/FindPage";
import LoadingPage from "./component/pages/LoadingPage";
import store from "./store";
import AdminPage from "./component/admin/AdminPage";

function App() {
    const { loadingStore } = store;
    return (
        <BrowserRouter>
            {loadingStore.isLoading && <LoadingPage />}

            <ScrollToTop />
            <div id="all">
                <Routes>
                    <Route
                        path="/Admin/:Path"
                        element={
                            <PostRoute component={<AdminPage />} content={16} />
                        }
                    />
                </Routes>
                <div className="container">
                    <HeaderComponent />
                    <GlobalNavBar />
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route
                            path="/Login"
                            element={
                                <PublicRoute
                                    component={<LoginPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/Find"
                            element={
                                <PublicRoute
                                    component={<FindPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/Signup"
                            element={
                                <PublicRoute
                                    component={<SignupPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/Mypage"
                            element={
                                <PrivateRoute
                                    component={<MyPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/Mypage/UserInfo"
                            element={
                                <PrivateRoute
                                    component={<UserInfoPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/:Category/:Sub"
                            element={
                                <ViewRoute
                                    component={<ForumPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/:Category/:Sub/post"
                            element={
                                <ViewRoute
                                    component={<PostPage />}
                                    content={15}
                                />
                            }
                        />
                        <Route
                            path="/:Category/:Sub/editor"
                            element={
                                <PostRoute
                                    component={<EditorPage />}
                                    content={16}
                                />
                            }
                        />

                        <Route path="/*" element={<NotFoundPage />} />
                    </Routes>
                </div>
                <FooterComponent />
            </div>
        </BrowserRouter>
    );
}

export default observer(App);
