// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import GlobalNavBar from "../navigation/GlobalNavBarComponent";
import { lnbIdList } from "../common/lnbID";

export default function AdminHeadersComponent() {
  const [status, setStatus] = useState(false);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  //lnb
  const [lnb, setLnb] = useState();
  // 말머리 받아오는거
  const [headers, setHeaders] = useState([]);

  // 작성한 말머리
  const [inputHeader, setInputHeader] = useState();

  const handleHeadersValue = (e) => {
    setInputHeader(e.target.value);
  };

  useEffect(() => {
    if (lnb) {
      axios
        .get(
          process.env.REACT_APP_API_HOST + "/api/heads/admin/" + lnbIdList[lnb]
        )
        .then((response) => {
          setHeaders(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [status, lnb]);
  const headersList = headers.map((header) => {
    return (
      <div className="brand" key={header.head_no}>
        <div>{`${header.head_name}`}</div>
        <button id={header.head_no} onClick={(e) => onClickDelete(e)}>
          X
        </button>
      </div>
    );
  });

  // 말머리 삽입
  const onClickInsert = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(13);
    setModalEvent(() => {
      const inserts = () => {
        insertBrandEvent();
      };
      return inserts;
    });
  };

  const insertBrandEvent = () => {
    if (inputHeader) {
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/heads/insert", {
          param: {
            head_name: inputHeader,
            cat_no: lnbIdList[lnb],
          },
        })
        .then((response) => {
          setModal(true);
          setModalType(1);
          setContentID(28);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  // 말머리 삭제
  const onClickDelete = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(11);
    setModalEvent(() => {
      const deletes = () => {
        deleteBrandEvent(e);
      };
      return deletes;
    });
  };

  const deleteBrandEvent = (e) => {
    axios
      .delete(
        process.env.REACT_APP_API_HOST + `/api/heads/delete/${e.target.id}`
      )
      .then((response) => {
        setModal(true);
        setModalType(1);
        setContentID(26);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">말머리</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-brand-container">
          <GlobalNavBar display={true} setLnb={setLnb} />
          <p className="admin-brand-explain">
            선택한 lnb에 대해 말머리 추가/삭제가 가능합니다.
          </p>
          <div className="admin-brand-insert">
            <p className="admin-brand-input-label">현재 선택된 lnb : {lnb}</p>
            <span className="admin-brand-input-label">말머리</span>
            <input
              className="admin-brand-input"
              value={inputHeader}
              onChange={(e) => {
                handleHeadersValue(e);
              }}
            ></input>

            <button
              onClick={() => {
                onClickInsert();
              }}
            >
              추가
            </button>
          </div>
          <div className="admin-brand-list"> {headersList}</div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
