// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../../Modal/Modal";

export default function AdminBrandComponent() {
  const [status, setStatus] = useState(false);
  const [brands, setBrands] = useState([]);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  // 브랜드
  const [brandTitle, setBrandTitle] = useState();
  const [brandDate, setBrandDate] = useState();

  const handleTitleValue = (e) => {
    setBrandTitle(e.target.value);
  };
  const handleDateValue = (e) => {
    setBrandDate(e.target.value);
  };

  useEffect(() => {
    setBrandTitle("");
  }, [status]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/mainPages/3")
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [status]);

  const brandList = brands.map((brand) => {
    return (
      <div className="brand" key={brand.brand_no}>
        <div>{`${brand.brand_when}, ${brand.brand_name}`}</div>
        <button id={brand.brand_no} onClick={(e) => onClickDelete(e)}>
          X
        </button>
      </div>
    );
  });

  // 달력 삽입, 업데이트
  const onClickInsert = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(13);
    setModalEvent(() => {
      const inserts = () => {
        insertBrandEvent();
      };
      return inserts;
    });
  };

  const insertBrandEvent = () => {
    if (brandDate && brandTitle) {
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/admins/brands/insert", {
          param: {
            brand_name: brandTitle,
            brand_when: brandDate,
          },
        })
        .then((response) => {
          setBrandTitle("");
          setModal(true);
          setModalType(1);
          setContentID(28);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  // 달력 삭제
  const onClickDelete = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(11);
    setModalEvent(() => {
      const deletes = () => {
        deleteBrandEvent(e);
      };
      return deletes;
    });
  };

  const deleteBrandEvent = (e) => {
    axios
      .delete(
        process.env.REACT_APP_API_HOST +
          `/api/admins/brands/delete/${e.target.id}`
      )
      .then((response) => {
        setModal(true);
        setModalType(1);
        setContentID(26);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">브랜드</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-brand-container">
          <span className="admin-brand-title"> 애드파워와 함께한 브랜드</span>
          <hr />
          <p className="admin-brand-explain">
            브랜드 최대 15개까지 추가할 수 있습니다. 일시는 연도-회기 순으로
            입력하며, 특수문자 입력은 제한됩니다.
          </p>
          <div className="admin-brand-insert">
            <span className="admin-brand-input-label">일시</span>
            <input
              className="admin-brand-input"
              value={brandDate}
              onChange={(e) => {
                handleDateValue(e);
              }}
            ></input>
            <span className="admin-brand-input-label">브랜드</span>
            <input
              className="admin-brand-input"
              value={brandTitle}
              onChange={(e) => {
                handleTitleValue(e);
              }}
            ></input>
            <button
              onClick={() => {
                onClickInsert();
              }}
            >
              추가
            </button>
          </div>
          <div className="admin-brand-list"> {brandList}</div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
