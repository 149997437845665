import axios from "axios";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import store from "../../store";
import ImageComponent from "../common/ImageComponent";

function HeartListComponent({ postNo }) {
  // 좋아요 인원 표시 컴포넌트

  //Mobx
  const { userStore, loadingStore } = store;

  //유저 좋아요체크
  const [isLike, setIsLike] = useState(false);
  const [likeNo, setLikeNo] = useState();
  //현재 게시글 좋아요 리스트
  const [likeList, setLikeList] = useState([]);
  const [likeCount, setLikeCount] = useState(0);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/likes/", {
        params: { post_no: postNo, user_no: userStore.checkStatus().userNo },
      })
      .then((response) => {
        setIsLike(response.data.data.result);
        if (response.data.data.likes[0] != undefined) {
          setLikeNo(response.data.data.likes[0].like_no);
        }
      });
    //좋아요 카운트
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/likes/" + postNo)
      .then((response) => {
        setLikeList(response.data.data);
        setLikeCount(response.data.data.length);
      });
  }, [isLike, postNo]);

  useEffect(() => {
    likeList.forEach((like) => {
      if (like.user_nick === userStore.userNick) {
        setIsLike(true);
        setLikeNo(like.like_no);
      }
    });
  }, [likeList, isLike]);

  //좋아요 온오프
  // true면 취소하는거니까 삭제
  function onlikeClick() {
    loadingStore.setIstLoading(true);
    setIsLike((isLike) => !isLike);
    if (isLike) {
      axios
        .delete(process.env.REACT_APP_API_HOST + "/api/likes/delete/" + likeNo) //라이크 넘버 넣기
        .then((response) => {})
        .catch(function (error) {
          console.log(error.config);
        })
        .finally(() => loadingStore.setIstLoading(false));
    } else {
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/likes/insert", {
          param: {
            post_no: postNo,
            user_no: userStore.checkStatus().userNo,
          },
        })
        .then((response) => {})
        .catch(function (error) {
          console.log(error.config);
        })
        .finally(() => loadingStore.setIstLoading(false));
    }
  }

  //좋아요 리스트 출력
  const likeL = likeList.map((item) => {
    return (
      <div key={item.user_nick} className="grid-item">
        <img src={ImageComponent["Profile_user" + item.user_class]}></img>
        <span>{item.user_nick}</span>
      </div>
    );
  });

  return (
    <>
      <div className="post-like-count">
        <img
          src={
            isLike == true ? ImageComponent.Heart : ImageComponent.Empty_Heart
          }
          className="img_heart"
          onClick={() => {
            if (userStore.isLogin) {
              onlikeClick();
            }
          }}
        ></img>
        <span> 좋아요</span> <span>{likeCount}</span>
        <img src={ImageComponent.Dropdown} className="img_dropdown"></img>
      </div>

      <div className="post-like-grid">
        <div>{likeL}</div>
      </div>
    </>
  );
}

export default observer(HeartListComponent);
