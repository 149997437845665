import { React, useState } from "react";
import UserInfoComponent from "../mypage/UserInfoComponent";
import UserInfoCheckComponent from "../mypage/UserInfoCheckComponent";
import { useSearchParams } from "react-router-dom";
import SignOutComponent from "../mypage/SignOutComponent";

export default function UserInfoPage() {
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  return (
    <div>
      {step === 0 ? (
        <UserInfoCheckComponent setStep={setStep} />
      ) : (
        <>{type === "update" ? <UserInfoComponent /> : <SignOutComponent />}</>
      )}
    </div>
  );
}
