import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import "./MainComponent.css";

function MainTwo() {
  const [twoList, setTwoList] = useState([]);
  const [twoImage, setTwoImage] = useState();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/mainpages/2")
      .then((response) => {
        setTwoList(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    twoList.map((data) => {
      if (data.cat_no === 27) {
        setTwoImage(data.post_thumb1);
      }
    });
  }, [twoList]);

  const meeting = twoList.map((data) => {
    if (data.cat_no === 27) {
      return (
        <Link
          to={`/아카이빙/${data.cat_name}/post/?postNo=${data.post_no}&currentPage=1`}
          key={data.title}
        >
          <p className="main2-lnb">{data.cat_name}</p>
          <p className="main2-title clamp1">{data.title} </p>
        </Link>
      );
    }
  });

  const board = twoList.map((data) => {
    if (data.cat_no !== 27) {
      return (
        <Link
          to={`/커뮤니티/${data.cat_name}/post/?postNo=${data.post_no}&currentPage=1`}
          key={data.cat_name}
        >
          <div className="main2-subpost">
            <div>
              <p className="main2-sub-lnb">{data.cat_name}</p>
              <span>{data.post_ins_date?.slice(0, 10)}</span>
              <img src={ImageComponent.Shortcut} className="img_shortcut"></img>
            </div>
            <p className="main2-sub-title clamp1">{data.title}</p>
          </div>
        </Link>
      );
    }
  });

  return (
    <div className="main2">
      <div className="main-context">
        <div className="main2-mainpost">
          {twoList ? (
            <>
              {meeting}
              {board}
            </>
          ) : null}
        </div>

        <div className="main2-sublist">
          <div className="main2-img">
            <img className="img_aa" src={twoImage || ImageComponent.AA}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainTwo;
