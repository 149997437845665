// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../../Modal/Modal";
import { useRef } from "react";
import loadingStore from "../../../store/loadingStore";

export default function AdminHistoryComponent() {
  const [status, setStatus] = useState(false);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  //사용자가 업로드할 이미지
  const [imageUrl, setImageUrl] = useState("");
  const [inputText, setInputText] = useState("");

  // 서버에 저장되어있는 파일
  const [serverData, setServerData] = useState([]);

  const handleTextValue = (e) => {
    setInputText(e.target.value);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/adpowerinfo/history")
      .then((response) => {
        setServerData(response.data.data);
      });
  }, [status]);

  const ImageInput = useRef();
  const onClickImageUpload = () => {
    ImageInput.current.click();
  };

  const ImageOnChange = async (e) => {
    const inputfile = document.getElementById("image-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let size = 0;
    if (file) {
      loadingStore.setIstLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_HOST + `/api/files/upload/${size}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setImageUrl(response.data.fileurl);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingStore.setIstLoading(false);
        });
    }
  };

  // 삽입
  const onClickInsert = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(13);
    setModalEvent(() => {
      const update = () => {
        insertEvent();
      };
      return update;
    });
  };

  const insertEvent = () => {
    if (imageUrl && inputText) {
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/adpowerinfo/insert", {
          param: {
            ap_name: "연혁",
            ap_image_url: imageUrl,
            ap_note: inputText,
            user_no: 1,
          },
        })
        .then((response) => {
          setImageUrl("");
          setInputText("");
          setModal(true);
          setModalType(1);
          setContentID(28);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  // 삭제
  const onClickDelete = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(11);
    setModalEvent(() => {
      const deletes = () => {
        deleteEvent(e);
      };
      return deletes;
    });
  };
  const deleteEvent = (e) => {
    axios
      .delete(
        process.env.REACT_APP_API_HOST +
          `/api/adpowerinfo/delete/${e.target.id}`
      )
      .then((response) => {
        setModal(true);
        setModalType(1);
        setContentID(26);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const serverList = serverData.map((data) => {
    return (
      <div className="admin-server-data">
        <p>{data.ap_note}</p>
        <p
          onClick={() => {
            window.open(data.ap_image_url);
          }}
        >
          {data.ap_image_url}
        </p>
        <button id={data.ap_no} onClick={(e) => onClickDelete(e)}>
          X
        </button>
      </div>
    );
  });

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">연혁</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-popup-container">
          <p className="admin-popup-explain">
            년대와 이미지를 등록하세요. ex. 2020년대, 이미지 업로드
          </p>
          <input
            className="admin-brand-input"
            value={inputText}
            placeholder="2020년대"
            onChange={(e) => {
              handleTextValue(e);
            }}
          ></input>
          <input
            id="image-input"
            type="file"
            style={{ display: "none" }}
            ref={ImageInput}
            onChange={ImageOnChange}
            accept=".gif, .jpg, .png"
          />
          <button className="btn-thumbnail" onClick={onClickImageUpload}>
            이미지 업로드
          </button>
          <button
            onClick={() => {
              onClickInsert();
            }}
          >
            추가
          </button>
          <p>업로드 한 이미지확인</p>
          <img src={imageUrl}></img>
          <div>
            <hr />
            <p>현재 서버에 있는 이미지 (각 링크 클릭하여 확인): </p>
            {serverList}
          </div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
