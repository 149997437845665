import React from "react";
import "./ProfileComponent.css";
import ImageComponent from "../common/ImageComponent";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";

function ProfileComponent({ onClickProfile }) {
  //Mobx 설정
  const { userStore } = store;
  const uClass = userStore.checkStatus().userClass;
  const navigate = useNavigate();

  return (
    <div className="profile shadow">
      <div className="profile-top">
        <img
          src={ImageComponent["Profile_user" + uClass]}
          className="img_profile"
        ></img>
        <span className="profile-name clamp1">
          {userStore.checkStatus().userNick}
        </span>
        <button
          className="profile-logout"
          onClick={() => {
            localStorage.removeItem("jwtToken");
            userStore.setLogout();
            navigate("/");
            window.location.reload();
          }}
        >
          로그아웃
        </button>
      </div>
      <hr />
      <div className="profile-bottom">
        <Link
          to={`/Mypage?type=write&currentPage=1`}
          className="profile-mypage"
          onClick={onClickProfile}
        >
          마이페이지
        </Link>
        <span className="profile-between"> | </span>
        <Link
          to="/Mypage/UserInfo?type=update"
          className="profile-change"
          onClick={onClickProfile}
        >
          정보수정
        </Link>
      </div>
    </div>
  );
}
export default observer(ProfileComponent);
