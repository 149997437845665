import { makeAutoObservable } from "mobx";

class Loading {
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIstLoading(boolean) {
    this.isLoading = boolean;
  }
}

const loadingStore = new Loading();

export default loadingStore;
