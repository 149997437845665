import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import ImageComponent from "../common/ImageComponent";
import EditorReadComponent from "../editor/EditorReadComponent";
import { lnbIdList } from "../common/lnbID";
import ConvertCommentDateStyle from "./ConvertCommentDateStyle";

function PostContentComponent(props) {
    const nowParams = useParams();
    const [post, setPost] = useState([]);
    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_HOST + "/api/posts/findpost", {
                params: {
                    postid: props.postNo,
                    catid: lnbIdList[nowParams.Sub],
                },
            })
            .then((response) => {
                setPost(response.data.data[0]);
                if (response.data.data[0].user_no !== undefined)
                    props.setUserNo(response.data.data[0].user_no);
            });
    }, [props.postNo]);

    // 게시글 본문내용
    return (
        <div>
            <div className="post-main">
                <div className="post-main-title">
                    <span className="post-title-category">{post.cat_name}</span>
                    <span className="post-title-text">{post.title}</span>
                </div>
                <div className="post-main-count">
                    <p className="post-count-wrap">
                        <span className="post-count-label">조회</span>{" "}
                        <span> {post.post_hit}</span>
                    </p>
                    <p className="post-count-wrap">
                        <span className="post-count-label">댓글</span>{" "}
                        <span> {props.commentsCount}</span>
                    </p>
                </div>
            </div>
            <hr></hr>
            <div className="post-regist">
                <div className="post-regist-user">
                    <img
                        src={ImageComponent["Profile_user" + post.user_class]}
                        className="img_profile"
                    ></img>
                    <span>{post.user_nick}</span>
                </div>
                <div className="post-regist-time">
                    <div>{ConvertCommentDateStyle(post.post_ins_date)}</div>
                </div>
            </div>
            <div className="post-content">
                <EditorReadComponent content={post.post_content} />
            </div>
        </div>
    );
}

export default PostContentComponent;
