// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import GlobalNavBar from "../navigation/GlobalNavBarComponent";
import { lnbIdList } from "../common/lnbID";
import { useRef } from "react";
import loadingStore from "../../store/loadingStore";
import ImageComponent from "../common/ImageComponent";

export default function AdminPopupComponent() {
  const [status, setStatus] = useState(false);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  // 업로드한 이미지
  const [thumbnailUrl1, setThumbnailUrl1] = useState();

  //서버에서 가져온 팝업 이미지
  const [popup, setPopup] = useState();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/admins/popup")
      .then((response) => {
        setPopup(response.data.data[0]);
      });
  }, [status]);

  const [checked, setChecked] = useState(0);
  useEffect(() => {
    if (popup && popup.pop_set === 1) {
      setChecked(1);
      const checkboxes = document.getElementsByClassName("chk_imp");
      Array.from(checkboxes).forEach((checkbox) => {
        checkbox.checked = true;
      });
    }
  }, [popup]);
  const checkHandler = (e) => {
    if (e.target.checked) {
      setChecked(1);
    } else {
      setChecked(0);
    }
  };

  const ThumbnailInput = useRef();
  const onCickThumbnailUpload = () => {
    ThumbnailInput.current.click();
  };

  const ThumbnailOnChange = async (e) => {
    const inputfile = document.getElementById("thumbnail-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    var size = 5;
    loadingStore.setIstLoading(true);
    await axios
      .post(
        process.env.REACT_APP_API_HOST + `/api/files/upload/${size}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setThumbnailUrl1(response.data.fileurl);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        loadingStore.setIstLoading(false);
      });
  };

  // 팝업이미지 삽입
  const onClickInsert = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(13);
    setModalEvent(() => {
      const inserts = () => {
        insertBrandEvent();
      };
      return inserts;
    });
  };

  const insertBrandEvent = () => {
    if (thumbnailUrl1 || (popup.pop_image_url && !thumbnailUrl1)) {
      axios
        .put(process.env.REACT_APP_API_HOST + "/api/admins/update/popup", {
          param: [
            {
              pop_image_url: thumbnailUrl1 || popup.pop_image_url,
              pop_set: checked,
            },
            popup.pop_no,
          ],
        })
        .then((response) => {
          setModal(true);
          setModalType(1);
          setContentID(28);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">팝업</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-popup-container">
          <p className="admin-popup-explain">
            팝업 이미지는 width:600px로 고정이며 이미지는 한개만 등록
            가능합니다.
          </p>
          <div className="admin-popup-insert">
            <div>
              <div>
                기존 팝업 이미지 : &nbsp;&nbsp; &nbsp;
                <span>
                  {popup && popup.pop_set === 1
                    ? "현재 게시중"
                    : "현재 게시안함"}
                </span>
              </div>
              <img
                className="popup-img"
                src={popup && popup.pop_image_url}
              ></img>
            </div>
            <div>
              <div>변경할 팝업 이미지 : </div>
              <img
                className="popup-img"
                src={thumbnailUrl1 && thumbnailUrl1}
              ></img>
            </div>
          </div>
          <input
            id="thumbnail-input"
            type="file"
            style={{ display: "none" }}
            ref={ThumbnailInput}
            onChange={ThumbnailOnChange}
            accept=".gif, .jpg, .png"
          />
          <div className="admin-popup-mid">
            <button className="btn-thumbnail" onClick={onCickThumbnailUpload}>
              팝업 이미지 업로드
            </button>
          </div>
          <div className="admin-popup-bottom">
            <span className="admin-brand-input-label">게시 여부 체크</span>
            <input
              type={"checkbox"}
              id={`popup-checkbox`}
              className="chk_imp chk_imp_user"
              onChange={(e) => checkHandler(e)}
            ></input>
            <label htmlFor={`popup-checkbox`}>
              <img
                src={ImageComponent.Check_Red}
                className="img_checkred"
              ></img>
            </label>
            <button
              onClick={() => {
                onClickInsert();
              }}
            >
              서버에 등록
            </button>
          </div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
