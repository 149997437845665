import "./UserInfoComponent.css";
import React, { useState } from "react";
import ImageComponent from "../common/ImageComponent";
import SUComponent from "../signup/SUComponent";

export default function UserInfoComponent() {
  return (
    <>
      <SUComponent />
    </>
  );
}
