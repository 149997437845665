import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Editor.css";

import ImageComponent from "../common/ImageComponent";
import Modal from "../Modal/Modal";
import { lnbIdList, getLnbName } from "../common/lnbID";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EditorComponent from "./EditorComponent";
import DraftComponent from "../dropdown/DraftComponent";
import { observer } from "mobx-react";
import store from "../../store";
import LoadingPage from "../pages/LoadingPage";
import ReCaptcha from "../common/ReCaptchaComponent";
function EditorMenuComponent() {
  //리캡챠
  const [captcha, setCaptcha] = useState(false);

  //Mobx
  const { userStore, loadingStore } = store;

  const nowParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const postNo = searchParams.get("postNo");
  const [post, setPost] = useState([]);

  // 권한
  const [userInfo, setUserInfo] = useState(userStore.checkStatus());
  useEffect(() => {
    setUserInfo(userStore.checkStatus());
  }, [userStore]);

  const checkImportant = () => {
    if (userInfo.userClass >= 4) {
      return (
        <div className="board-important">
          <input
            type={"checkbox"}
            id="chk_imp"
            onChange={(e) => checkHandler(e)}
          ></input>
          <label htmlFor="chk_imp">
            <img src={ImageComponent.Check_Red} className="img_checkred"></img>
          </label>
          <label htmlFor="chk_imp">중요글</label>
        </div>
      );
    }
  };

  const [tempPost, setTempPost] = useState([]);
  const onClickLoad = (item) => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          `/api/posts/findpostinfo?postid=${item.post_no}&catid=${item.cat_no}`
      )
      .then((response) => {
        setTempPost(response.data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 게시글 수정시 사용
  useEffect(() => {
    if (postNo) {
      axios
        .get(process.env.REACT_APP_API_HOST + "/api/posts/findpostinfo", {
          params: {
            postid: postNo,
            catid: lnbIdList[nowParams.Sub],
          },
        })
        .then((response) => {
          setPost(response.data.data[0]);
          setSelectHead(response.data.data[0].head_name || "선택안함");
          setSelectHeadNo(response.data.data[0].head_no);
          setTitleValue(response.data.data[0].post_title);
          setText(response.data.data[0].post_content);
          setIChecked(response.data.data[0].post_is_important);
          setThumbnailUrl1(response.data.data[0].post_thumb1);
          setNameValue(response.data.data[0].post_username);
          if (response.data.data[0].post_is_important == 1) {
            document.getElementById("chk_imp").checked = true;
          }
        });
    } else if (tempPost.length !== 0) {
      setPost(tempPost);
      setSelectHead(tempPost.head_name);
      setSelectHeadNo(tempPost.head_no);
      setTitleValue(tempPost.post_title);
      setText(tempPost.post_content);
      setIChecked(tempPost.post_is_important);
      setThumbnailUrl1(tempPost.post_thumb1);
      if (tempPost.post_is_important == 1) {
        document.getElementById("chk_imp").checked = true;
      }
    }
  }, [postNo, tempPost]);

  const [titleValue, setTitleValue] = useState(""); //제목 입력
  const handleTitleValue = (e) => {
    setTitleValue(e.target.value);
  };
  const [nameValue, setNameValue] = useState(null); //이름 입력
  const handleNameValue = (e) => {
    setNameValue(e.target.value);
  };
  const [pwValue, setPwValue] = useState(null); //비밀번호 입력
  const handlePwValue = (e) => {
    setPwValue(e.target.value);
  };

  const [dHead, setdHead] = React.useState(false); //드롭박스

  const ref = useRef(); //말머리 외부영역클릭
  const handleClickOutSide = (e) => {
    if (dHead && !ref.current.contains(e.target)) {
      setdHead(false);
    }
  };
  useEffect(() => {
    if (dHead) document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  });

  const [headList, setHeadList] = useState([]);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/api/heads/" +
          lnbIdList[nowParams.Sub]
      )
      .then((response) => {
        setHeadList(response.data.data);
      });
  }, []);
  const [selectHead, setSelectHead] = useState("말머리"); //선택한 말머리
  const [selectHeadNo, setSelectHeadNo] = useState(null); //말머리 넘버
  const onClickHead = (e) => {
    setSelectHead(e.target.textContent);
    setSelectHeadNo(e.target.id);
  };

  const heads = headList.map((head) => {
    return (
      <li onClick={(e) => onClickHead(e)} id={head.head_no} key={head.head_no}>
        {head.head_name || "선택안함"}
        <img src={ImageComponent.Check} className="img_check"></img>
      </li>
    );
  });

  //임시저장
  const [draft, setDraft] = useState(false);
  function onClickDraft(e) {
    if (e.target.innerText == "임시저장") {
      setDraft(!draft);
    }
  }

  // 모달 관련 함수 선언,
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  const navigate = useNavigate();
  const onClickModal = (e) => {
    if (e.target.innerText == "취소") {
      setModal(!modal);
      setModalType(2);
      setContentID(4);
      setModalEvent(() => {
        const check = () => {
          checkModal();
        };
        return check;
      });
    } else if (e.target.innerText !== "취소") {
      if (
        (titleValue && text && selectHeadNo !== null) ||
        (titleValue && text && nameValue && pwValue)
      ) {
        onClickPost();
      } else {
        setModal(!modal);
        setModalType(1);
        setContentID(4);
        setModalEvent();
      }
    }
  };

  const checkModal = () => {
    setModalType(1);
    setContentID(6);
    setModalEvent(() => {
      const newpage = () => {
        navigate(-1);
      };
      return newpage;
    });
    setModal(!modal);
  };

  const [thumbnailUrl1, setThumbnailUrl1] = useState();

  const ThumbnailOnChange = async (e) => {
    const inputfile = document.getElementById("thumbnail-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    var size;
    {
      nowParams.Sub === "집회 리뷰"
        ? (size = 2)
        : nowParams.Sub === "전시회"
        ? (size = 3)
        : nowParams.Sub === "공지사항"
        ? (size = 4)
        : (size = 1);
    }
    loadingStore.setIstLoading(true);
    await axios
      .post(
        process.env.REACT_APP_API_HOST + `/api/files/upload/${size}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setThumbnailUrl1(response.data.fileurl);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        loadingStore.setIstLoading(false);
      });
  };

  const ThumbnailInput = useRef();
  const onCickThumbnailUpload = () => {
    ThumbnailInput.current.click();
  };

  //체크박스
  const [iChecked, setIChecked] = useState(0);
  const checkHandler = ({ target }) => {
    if (iChecked == 0) {
      setIChecked(1);
    } else {
      setIChecked(0);
    }
  };

  //게시글등록
  //TODO: 신입모집 Q&A의 경우 이름/비번 쳐야해서 달라질듯함
  function onClickPost() {
    if (titleValue.length > 100) {
      setModal(!modal);
      setContentID("7-1");
      return;
    }
    if (contentsError) {
      setModal(!modal);
      setContentID("8-1");
      return;
    }
    if (!postNo) {
      if ((nowParams.Sub === "Q&A" && captcha) || nowParams.Sub !== "Q&A") {
        loadingStore.setIstLoading(true);
        axios
          .post(process.env.REACT_APP_API_HOST + "/api/posts/insert", {
            param: {
              user_no:
                nowParams.Sub !== "Q&A" ? userStore.checkStatus().userNo : null,
              cat_no: lnbIdList[nowParams.Sub],
              head_no: selectHeadNo || null,
              post_title: titleValue,
              post_content: text,
              post_status: 1,
              post_is_important: iChecked,
              post_thumb1: thumbnailUrl1,
              post_username: nameValue || null,
              post_password1: pwValue,
            },
          })
          .then((response) => {
            navigate(`/${nowParams.Category}/${nowParams.Sub}?currentPage=1`);
          })
          .catch(function (error) {
            console.log(error.config);
          })
          .finally(() => loadingStore.setIstLoading(false));
      } else {
        setModal(!modal);
        setContentID(24);
      }
    } else {
      loadingStore.setIstLoading(true);
      axios
        .put(process.env.REACT_APP_API_HOST + "/api/posts/update", {
          param: [
            {
              //   user_no:
              //     nowParams.Sub !== "Q&A" ? userStore.checkStatus().userNo : null,
              cat_no: lnbIdList[nowParams.Sub],
              head_no: selectHeadNo || null,
              post_title: titleValue,
              post_content: text,
              post_status: 1,
              post_is_important: iChecked,
              post_thumb1: thumbnailUrl1,
            },
            postNo,
          ],
        })
        .then((response) => {
          navigate(
            `/${nowParams.Category}/${nowParams.Sub}/post/?postNo=${postNo}&search=&currentPage=1`
          );
        })
        .catch(function (error) {
          console.log(error.config);
        })
        .finally(() => loadingStore.setIstLoading(false));
    }
  }

  function onClickTemp() {
    if (
      (titleValue && text && selectHeadNo !== null) ||
      (titleValue && text && nameValue && pwValue)
    ) {
      loadingStore.setIstLoading(true);
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/posts/insert", {
          param: {
            user_no: userStore.checkStatus().userNo,
            cat_no: lnbIdList[nowParams.Sub],
            head_no: selectHeadNo,
            post_title: titleValue,
            post_content: text,
            post_status: 2,
            post_is_important: iChecked,
            post_thumb1: thumbnailUrl1,
            post_username: nameValue,
            post_password1: pwValue,
          },
        })
        .then((response) => {
          setModal(!modal);
          setModalType(1);
          setContentID(18);
          setModalEvent(() => {
            const complete = () => {
              navigate(`/${nowParams.Category}/${nowParams.Sub}?currentPage=1`);
            };
            return complete;
          });
        })
        .catch(function (error) {
          console.log(error.config);
        })
        .finally(() => loadingStore.setIstLoading(false));
    } else {
      setModal(!modal);
      setModalType(1);
      setContentID(19);
    }
  }

  // quill 본문
  const [text, setText] = useState();
  const [contents, setContents] = useState(null);
  //게시글 숫자
  const [contentsError, setContentsError] = useState(false);
  useEffect(() => {
    const editor = document.getElementsByClassName("ql-editor")[0];
    if (editor && editor.innerHTML && editor.innerHTML.length > 10000) {
      setContentsError(true);
    } else {
      setContentsError(false);
    }
    setContents(editor);
  }, [text]);

  return (
    <div className="board">
      {/* 로딩 상태에 따라 NullPage 렌더링 */}
      {/* 위 */}
      <div className="board-top board-flex">
        <span>글쓰기</span>
        <button
          className="board-top-btn middle-margin"
          onClick={(e) => onClickDraft(e)}
          style={{ visibility: nowParams.Sub === "Q&A" && "hidden" }}
        >
          임시저장
          {draft ? (
            <DraftComponent
              setDraft={setDraft}
              onClickTemp={onClickTemp}
              onClickLoad={onClickLoad}
              tempPost={tempPost}
            />
          ) : null}
        </button>

        <Modal
          open={modal}
          close={() => {
            setModal(!modal);
          }}
          type={modalType}
          content={contentid}
          modalEvent={modalEvent}
        />
        <button className="board-top-btn" onClick={onClickModal}>
          취소
        </button>
        <button className="board-top-btn btn-write" onClick={onClickModal}>
          {postNo ? "수정" : "등록"}
        </button>
      </div>
      <hr />
      {/* 중간 */}
      <div className="board-mid ">
        {nowParams.Sub !== "Q&A" ? (
          <>
            <div className="board-mid-category">
              <div
                className="dropdown"
                onMouseUpCapture={() => setdHead(!dHead)}
                ref={ref}
              >
                {selectHead}
                <div
                  className="dropdown-list"
                  style={{
                    display: dHead === true ? "block" : "none",
                  }}
                >
                  {/* 말머리 리스트 */}
                  <ul>{heads}</ul>
                </div>
                <img
                  src={
                    dHead === true
                      ? ImageComponent.Dropdown_Up
                      : ImageComponent.Dropdown
                  }
                  className="img_dropdown"
                ></img>
              </div>
            </div>
            <input
              id="thumbnail-input"
              type="file"
              style={{ display: "none" }}
              ref={ThumbnailInput}
              onChange={ThumbnailOnChange}
              accept=".gif, .jpg, .png"
            />
            <button className="btn-thumbnail" onClick={onCickThumbnailUpload}>
              썸네일 등록
            </button>
            {thumbnailUrl1 ? (
              <>
                <img
                  className="thumbnail-preview"
                  src={thumbnailUrl1}
                  onClick={() => {
                    window.open(thumbnailUrl1);
                  }}
                ></img>
                <button
                  className="thumbnail-delete"
                  onClick={() => setThumbnailUrl1(null)}
                >
                  x
                </button>
              </>
            ) : null}
          </>
        ) : (
          <>
            {/* 신입모집일 경우 말머리,썸네일대신 이름 비밀번호 */}
            <input
              className="board-title-input"
              type="text"
              placeholder="이름"
              value={nameValue}
              onChange={(e) => handleNameValue(e)}
              readOnly={post.length !== 0 ? true : false}
            ></input>
            <input
              className="board-title-input"
              type="password"
              placeholder={
                post.length === 0
                  ? "비밀번호"
                  : "비밀번호는 수정할 수 없습니다."
              }
              value={pwValue}
              onChange={(e) => handlePwValue(e)}
              readOnly={post.length !== 0 ? true : false}
            ></input>
            <ReCaptcha setCaptcha={setCaptcha} className="qna-recaptcha" />
          </>
        )}
      </div>
      {/* 아래 */}
      <div className="board-bottom ">
        <div className="board-title">
          {/* 이모티콘 들어가게 해야함 */}
          <input
            className="board-title-input"
            type="text"
            placeholder="제목을 입력해 주세요."
            value={titleValue}
            onChange={(e) => handleTitleValue(e)}
          ></input>
        </div>
        {checkImportant()}
      </div>
      {/* Quill */}
      <EditorComponent text={text} setText={setText} />
    </div>
  );
}

export default observer(EditorMenuComponent);
