import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState, useRef } from "react";
import store from "../../store";
import { useParams } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";

import "./InfoComponent.css";

function InfoComponent({}) {
  // store
  const { loadingStore } = store;
  const nowParams = useParams();
  const [data, setData] = useState([]);
  const [dHead, setdHead] = useState(false); //드롭박스

  const ref = useRef(); //말머리 외부영역클릭
  const handleClickOutSide = (e) => {
    if (dHead && !ref.current.contains(e.target)) {
      setdHead(false);
    }
  };
  useEffect(() => {
    if (dHead) document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  });
  const onClickHead = (e) => {
    setSelectHead(e.target.textContent);
    setSelectHeadNo(e.target.id);
  };

  const [headList, setHeadList] = useState([]);

  const heads = headList.map((head, idx) => {
    return (
      <li onClick={onClickHead} id={idx} key={head}>
        {head}
        <img src={ImageComponent.Check} className="img_check"></img>
      </li>
    );
  });
  const [selectHeadNo, setSelectHeadNo] = useState(0);
  const [selectHead, setSelectHead] = useState(headList[selectHeadNo]);

  useEffect(() => {
    setSelectHead(headList[selectHeadNo]);
  }, [nowParams, headList]);

  useEffect(() => {
    setSelectHeadNo(0);
    if (
      nowParams.Sub === "소개" ||
      nowParams.Sub === "BI" ||
      nowParams.Sub === "회칙"
    ) {
      axios
        .get(process.env.REACT_APP_API_HOST + "/api/adpowerInfo/single", {
          params: {
            category: nowParams.Sub,
          },
        })
        .then((response) => {
          setData(response.data.data[0]);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (nowParams.Sub === "운영위원회") {
      axios
        .get(process.env.REACT_APP_API_HOST + "/api/adpowerInfo/people")
        .then((response) => {
          setData(response.data.data);
          setHeadList(
            response.data.data.map((value) => {
              return value.ap_note;
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (nowParams.Sub === "연혁") {
      axios
        .get(process.env.REACT_APP_API_HOST + "/api/adpowerInfo/history")
        .then((response) => {
          setData(response.data.data);
          setHeadList(
            response.data.data.map((value) => {
              return value.ap_note;
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (nowParams.Sub === "모집 요강") {
      axios
        .get(process.env.REACT_APP_API_HOST + "/api/adpowerInfo/recruit1")
        .then((response) => {
          setData(response.data.data);
          setHeadList(
            response.data.data.map((value) => {
              return value.ap_note;
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setData([]);
    }
  }, [nowParams]);

  // 다운로드
  const getFile = (url) => {
    // loadingStore.setIstLoading(true);
    fetch(url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        if (nowParams.Sub === "소개") a.download = "ADPOWER 동아리 소개서";
        // 여기서 파일명을 바꿉니다.
        else if (nowParams.Sub === "BI") a.download = "ADPOWER 동아리 BI";
        else if (nowParams.Sub === "모집 요강") a.download = "모집요강";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
    // .finally(() => loadingStore.setIstLoading(false));
  };

  return (
    <div className="info">
      {data.length !== 0 && data.ap_image_url !== undefined ? (
        <div className="info-container">
          <img src={data && data.ap_image_url}></img>
        </div>
      ) : null}
      {data.length !== 0 && data.ap_file_url ? (
        <div className="info-container">
          <button
            onClick={() => {
              getFile(data.ap_file_url);
            }}
          >
            다운로드
          </button>
        </div>
      ) : null}

      {(nowParams.Sub === "연혁" ||
        nowParams.Sub === "운영위원회" ||
        nowParams.Sub === "모집 요강") && (
        <>
          <div
            className="dropdown"
            onMouseUpCapture={() => setdHead(!dHead)}
            ref={ref}
          >
            {selectHead}
            <div
              className="dropdown-list"
              style={{ display: dHead === true ? "block" : "none" }}
            >
              {/* 말머리 리스트 */}
              <ul>{heads}</ul>
            </div>
            <img
              src={
                dHead === true
                  ? ImageComponent.Dropdown_Up
                  : ImageComponent.Dropdown
              }
              className="img_dropdown"
            ></img>
          </div>
          <div className="info-container">
            <img
              src={
                data.length !== 0 && data.length >= 1
                  ? data[selectHeadNo].ap_image_url
                  : null
              }
            ></img>
          </div>
          {data.length !== 0 && data[selectHeadNo]?.ap_file_url ? (
            <div className="info-container">
              <button
                onClick={() => {
                  getFile(data[selectHeadNo].ap_file_url);
                }}
              >
                모집 요강 다운로드
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default observer(InfoComponent);
