// Loading.js
import React from "react";
import ImageComponent from "../common/ImageComponent";

export default function LoadingPage(props) {
  return (
    <div className="loading-background">
      <img src={ImageComponent.Spinner} alt="로딩중" width="10%" />
    </div>
  );
}
