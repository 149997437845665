// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../../Modal/Modal";
import { useRef } from "react";
import loadingStore from "../../../store/loadingStore";

export default function AdminRulesComponent() {
  const [status, setStatus] = useState(false);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  //사용자가 업로드한 파일, 이미지
  const [imageUrl, setImageUrl] = useState();
  const [fileUrl, setFileUrl] = useState();

  // 서버에 저장되어있는 파일
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [apNo, setApNo] = useState("");

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/adpowerInfo/single", {
        params: {
          category: "회칙",
        },
      })
      .then((response) => {
        setImage(response.data.data[0].ap_image_url);
        setFile(response.data.data[0].ap_file_url);
        setApNo(response.data.data[0].ap_no);
      });
  }, [status]);

  const ImageInput = useRef();
  const onClickImageUpload = () => {
    ImageInput.current.click();
  };

  const FileInput = useRef();
  const onClickFileUpload = () => {
    FileInput.current.click();
  };

  const ImageOnChange = async (e) => {
    const inputfile = document.getElementById("image-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let size = 0;
    if (file) {
      loadingStore.setIstLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_HOST + `/api/files/upload/${size}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setImageUrl(response.data.fileurl);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingStore.setIstLoading(false);
        });
    }
  };

  const FileOnChange = async (e) => {
    const inputfile = document.getElementById("file-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    if (file) {
      loadingStore.setIstLoading(true);
      await axios
        .post(process.env.REACT_APP_API_HOST + `/api/files/files`, formData, {
          headers: {
            "Content-Type": "undefined",
          },
        })
        .then((response) => {
          setFileUrl(response.data.fileurl);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingStore.setIstLoading(false);
        });
    }
  };
  // 수정
  const onClickChange = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(12);
    setModalEvent(() => {
      const update = () => {
        updateEvent();
      };
      return update;
    });
  };

  const updateEvent = () => {
    if (imageUrl && fileUrl) {
      axios
        .put(process.env.REACT_APP_API_HOST + "/api/adpowerinfo/update", {
          param: [
            {
              ap_name: "회칙",
              ap_image_url: imageUrl,
              ap_file_url: fileUrl,
            },
            apNo,
          ],
        })
        .then((response) => {
          setImageUrl();
          setFileUrl();
          setModal(true);
          setModalType(1);
          setContentID(27);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">회칙</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-popup-container">
          <p className="admin-popup-explain">
            한개의 이미지와 파일만 등록 가능
          </p>
          <input
            id="image-input"
            type="file"
            style={{ display: "none" }}
            ref={ImageInput}
            onChange={ImageOnChange}
            accept=".gif, .jpg, .png"
          />
          <button className="btn-thumbnail" onClick={onClickImageUpload}>
            이미지 업로드
          </button>
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            ref={FileInput}
            onChange={FileOnChange}
            accept=".pdf"
          />
          <button className="btn-thumbnail" onClick={onClickFileUpload}>
            파일 업로드
          </button>
          <button
            onClick={() => {
              onClickChange();
            }}
          >
            수정
          </button>
          <hr />
          <p>업로드 한 이미지, 파일 확인</p>
          <p>이미지 : </p>
          <img src={imageUrl}></img>
          <p>파일명 :{fileUrl && fileUrl.split("/")[4]} </p>
          <div>
            <hr />
            <p>현재 서버 이미지 : </p>
            <img src={image}></img>
            <p>파일명 : {file && file.split("/")[4]}</p>
          </div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
