import React, { Component } from "react";
import ImageComponent from "../common/ImageComponent";
import { Link, useNavigate } from "react-router-dom";

import "./MainComponent.css";
import BrandSliderComponent from "./BrandSliderComponent";
import ArchiveSliderComponent from "./ArchiveSliderComponent";
import Best3Card from "./Best3Card";

function MainThreeComponent() {
    const navigate = useNavigate();

    return (
        <div className="main3">
            <Best3Card />

            {/* 주요프로젝트 */}
            <div className="main-context main-projects main3-distance">
                <div className="main3-text">
                    <p>Main Project</p>
                    <p>애드파워</p>
                    <p>주요 프로젝트</p>
                </div>
                <div className="project-img">
                    <Link to="/포트폴리오/프로젝트?currentPage=1">
                        <img src={ImageComponent.Project}></img>
                    </Link>
                    <Link to="/포트폴리오/애카이브?currentPage=1">
                        <img src={ImageComponent.Apchive}></img>
                    </Link>
                </div>
                <div className="project-img">
                    <Link to="/아카이빙/전시회?currentPage=1">
                        <img src={ImageComponent.Exhibition}></img>
                    </Link>
                    <Link to="/포트폴리오/PBA?currentPage=1">
                        <img src={ImageComponent.PBA}></img>
                    </Link>
                </div>
            </div>

            {/* 브랜드 */}
            <div className="main-context main-brand">
                <div className="main3-text">
                    <p>Brands</p>
                    <p>애드파워와</p>
                    <p>함께 한 브랜드</p>
                </div>
                <div className="brand-space">
                    <BrandSliderComponent />
                </div>
            </div>

            {/* 애드파워는 지금 */}
            <div className="main-context main-now">
                <div className="main3-text main-now-text">
                    <p>Contents Archive</p>
                    <p>애드파워는 지금</p>
                </div>
                {/* <CenterMode /> */}
                <ArchiveSliderComponent />
            </div>

            {/* SNS연결 */}
            <div className="main-context sns-background">
                <div
                    className="sns"
                    onClick={() =>
                        window.open(
                            "https://www.instagram.com/adpower.insta/",
                            "_blank"
                        )
                    }
                >
                    <img
                        className="sns-logo"
                        src={ImageComponent.Instagram}
                    ></img>
                    <div>
                        <p className="sns-name">인스타그램</p>
                        <p className="sns-exp">애드파워의 새로운 소식을</p>
                        <p className="sns-exp">빠르게 알고 싶은 사람들에게</p>
                    </div>
                </div>

                <div
                    className="sns"
                    onClick={() =>
                        window.open(
                            "https://www.youtube.com/channel/UCnwHp93EpBo1VOSuII3lmCg",
                            "_blank"
                        )
                    }
                >
                    <img
                        className="sns-logo"
                        src={ImageComponent.Youtube}
                    ></img>
                    <div>
                        <p className="sns-name">유튜브</p>
                        <p className="sns-exp">애드파워의 다양한 콘텐츠가</p>
                        <p className="sns-exp">궁금한 사람들에게</p>
                    </div>
                </div>

                <div
                    className="sns"
                    onClick={() =>
                        window.open(
                            "https://blog.naver.com/adpowerblog",
                            "_blank"
                        )
                    }
                >
                    <img className="sns-logo" src={ImageComponent.Blog}></img>
                    <div>
                        <p className="sns-name">블로그</p>
                        <p className="sns-exp">애파리안들이 직접 쓴 글이</p>
                        <p className="sns-exp">궁금한 사람들에게</p>
                    </div>
                </div>

                <div
                    className="sns"
                    onClick={() =>
                        window.open(
                            "https://ko-kr.facebook.com/adpowerkorea/",
                            "_blank"
                        )
                    }
                >
                    <img
                        className="sns-logo"
                        src={ImageComponent.Facebook}
                    ></img>
                    <div>
                        <p className="sns-name">페이스북</p>
                        <p className="sns-exp">과거 애드파워의 기록이</p>
                        <p className="sns-exp">궁금한 사람들에게</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainThreeComponent;
