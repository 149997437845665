import React, { useState, useRef, useEffect } from "react";
import ImageComponent from "../common/ImageComponent";
import { useNavigate } from "react-router-dom";

function Best3Card() {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);

    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.015 }
        );

        observer.observe(imageRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className="main-context" ref={imageRef}>
            {/* 최고최초최대 */}
            <div className="main3-info main3-distance">
                <img
                    src={isVisible ? ImageComponent.Main3 : ""}
                    alt="최초, 최고, 최대, 연합 광고동아리 애드파워입니다."
                    onClick={() => {
                        navigate(`/애드파워/소개?currentPage=1`);
                    }}
                />
            </div>
            <div
                className="main3-best3"
                onClick={() => {
                    navigate(`/애드파워/소개?currentPage=1`);
                }}
            >
                <section className="best3-images">
                    <article className="best3-image">
                        <img
                            src={isVisible ? ImageComponent.Main3 : ""}
                            alt="최초, 1989년 6월 창립된 최초의 대학생 연합광고동아리"
                        />
                    </article>
                    <article className="best3-image">
                        <img
                            src={isVisible ? ImageComponent.Main3 : ""}
                            alt="최고, 광고의 힘을 통해 세상의 긍정적 변화를 도모하는 최고의 크리에이티브"
                        />
                    </article>
                    <article className="best3-image">
                        <img
                            src={isVisible ? ImageComponent.Main3 : ""}
                            alt="최대, 국내 최대 규모의 연합 광고동아리"
                        />
                    </article>
                </section>
            </div>
        </div>
    );
}

export default Best3Card;
