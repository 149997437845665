import axios from "axios";
import { React, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import store from "../../store";
import ReCaptcha from "../common/ReCaptchaComponent";
import Modal from "../Modal/Modal";
import "./FindComponent.css";

export default function FindComponent() {
  // store
  const { loadingStore } = store;

  // 리캡챠
  const [captcha, setCaptcha] = useState(false);

  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");

  // 아이디/비밀번호
  const changeTypeToId = () => {
    searchParams.set("type", "id");
    setSearchParams(searchParams);
  };
  const changeTypeToPw = () => {
    searchParams.set("type", "pw");
    setSearchParams(searchParams);
  };

  //아이디 찾기
  const [nameValue, setNameValue] = useState("");
  const handleNameValue = (e) => {
    setNameValue(e.target.value);
  };
  const [emailValue, setEmailValue] = useState("");
  const handleEmailValue = (e) => {
    setEmailValue(e.target.value);
  };
  const [idValue, setIdValue] = useState("");
  const handleIdValue = (e) => {
    setIdValue(e.target.value);
  };

  useEffect(() => {
    setNameValue("");
    setIdValue("");
    setEmailValue("");
    setCaptcha(false);
  }, [type]);

  const onClickFind = () => {
    if (
      (type == "pw" && nameValue && idValue && emailValue && captcha) ||
      (type == "id" && nameValue && emailValue && captcha)
    ) {
      loadingStore.setIstLoading(true);
      if (type !== "pw") {
        axios
          .post(process.env.REACT_APP_API_HOST + "/api/users/findid", {
            param: {
              user_name: nameValue,
              user_email: emailValue,
            },
          })
          .then((response) => {
            if (response.data.status === "ok") {
              setModal(!modal);
              setContentID(21);
              setModalEvent(() => {
                const reload = () => {
                  window.location.reload();
                };
                return reload;
              });
            } else {
              setModal(!modal);
              setContentID(22);
            }
          })
          .catch(function (error) {
            console.log(error.config);
          })
          .finally(() => loadingStore.setIstLoading(false));
      } else {
        axios
          .post(process.env.REACT_APP_API_HOST + "/api/users/findpw", {
            param: {
              user_name: nameValue,
              user_id: idValue,
              user_email: emailValue,
            },
          })
          .then((response) => {
            if (response.data.status === "ok") {
              setModal(!modal);
              setContentID(23);
              setModalEvent(() => {
                const reload = () => {
                  window.location.reload();
                };
                return reload;
              });
            } else {
              setModal(!modal);
              setContentID(22);
            }
          })
          .catch(function (error) {
            console.log(error.config);
          })
          .finally(() => loadingStore.setIstLoading(false));
      }
    } else {
      setModal(!modal);
      setContentID(24);
    }
  };

  return (
    <div className="find">
      <div className="find-type">
        <div
          className={type !== "pw" ? "checked" : ""}
          onClick={() => {
            changeTypeToId();
          }}
        >
          아이디 찾기
        </div>
        <div
          className={type === "pw" ? "checked" : ""}
          onClick={() => {
            changeTypeToPw();
          }}
        >
          비밀번호 찾기
        </div>
      </div>
      <hr className="find-hr" />
      <div className="find-inputs">
        <div className="find-input-container">
          <p>이름 입력 : </p>
          <input
            placeholder="이름을 입력하세요"
            value={nameValue}
            onChange={(e) => {
              handleNameValue(e);
            }}
          ></input>
        </div>
        {type === "pw" ? (
          <div className="find-input-container">
            <p>아이디 입력 : </p>
            <input
              placeholder="아이디를 입력하세요"
              value={idValue}
              onChange={(e) => {
                handleIdValue(e);
              }}
            ></input>
          </div>
        ) : null}
        <div className="find-input-container">
          <p>이메일 입력 : </p>
          <input
            placeholder="이메일을 입력하세요"
            value={emailValue}
            onChange={(e) => {
              handleEmailValue(e);
            }}
          ></input>
        </div>
      </div>
      <ReCaptcha setCaptcha={setCaptcha} type={type} />

      <div className="find-btn-container">
        <button
          className="find-btn"
          onClick={() => {
            onClickFind();
          }}
        >
          찾기
        </button>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={1}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
