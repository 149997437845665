import { React } from "react";
import MyPageComponent from "../mypage/MyPageComponent";

export default function MyPage(props) {
  return (
    <>
      <MyPageComponent />
    </>
  );
}
