import LocalNavBarComponent from "../navigation/LocalNavBarComponent";
import FindComponent from "../info/FindComponent";

function FindPage() {
  return (
    <div>
      <LocalNavBarComponent />
      <FindComponent />
    </div>
  );
}

export default FindPage;
