import "./UserInfoComponent.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import store from "../../store";
import axios from "axios";

export default function SignOutComponent() {
  //Mobx 설정
  const { userStore } = store;

  const navigate = useNavigate();
  // 모달 관련 함수 선언,
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState();
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트
  const executeSignOut = () => {
    axios
      .delete(
        process.env.REACT_APP_API_HOST +
          `/api/users/delete/${userStore.checkStatus().userNo}`
      )
      .then((response) => {
        setModalType(1);
        setContentID(20);
        setModalEvent(() => {
          const reloadPage = () => {
            setModal(false);
            userStore.setLogout();
            navigate("/");
          };
          return reloadPage;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="login-container ">
      <div className="shadow login-box pwCheck">
        <div className="signout-center">
          <h1>회원 탈퇴</h1>
          <p>탈퇴시 작성된 게시글은 자동으로 삭제되지 않습니다.</p>
          <p>이용자의 개인정보는 회원 탈퇴 요청 시 바로 파기됩니다.</p>
          <p>정말 탈퇴하시겠습니까? </p>

          <button
            className="btn-write btn-login"
            onClick={() => {
              setModal(!modal);
              setContentID(10);
              setModalType(2);
              setModalEvent(() => {
                const signout = () => {
                  executeSignOut();
                };
                return signout;
              });
            }}
          >
            탈퇴
          </button>
          <button
            className="btn-login"
            onClick={() => {
              navigate(-1);
            }}
          >
            취소
          </button>
        </div>
      </div>
      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
