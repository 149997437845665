import React, { useEffect, useState } from "react";
import "./Modal.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "./Modal";
import store from "../../store";

const CheckModal = (props) => {
  // store
  const { loadingStore } = store;
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  const { open, close, checkContentId, postNo, checkModalEvent } = props;

  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(); //모달 버튼개수
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  const contents = {
    1: ["게시글 삭제를 위한 ", <br />, "비밀번호를 입력해주세요"],
    2: ["게시글 수정을 위한 ", <br />, "비밀번호를 입력해주세요"],
  };

  // 검색어 입력
  const [textValue, setTextValue] = useState(""); //제목
  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };
  useEffect(() => {
    setTextValue("");
  }, [open]);

  const onClickPwCheck = () => {
    loadingStore.setIstLoading(true);
    axios
      .post(process.env.REACT_APP_API_HOST + "/api/posts/checkpw", {
        param: {
          post_no: postNo,
          password: textValue,
        },
      })
      .then((response) => {
        if (response.data.status === "ok") {
          checkModalEvent();
        } else {
          setModalType(1);
          setContentID(17);
          setModal(!modal);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => loadingStore.setIstLoading(false));
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickPwCheck(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <div className={open ? "openModal modal" : "modal"}>
      {open ? (
        <section className="shadow">
          <main className="check-main">{contents[checkContentId]}</main>
          <input
            className="modal-pw-input"
            type="password"
            placeholder="비밀번호를 입력하세요"
            value={textValue}
            onChange={(e) => handleSetValue(e)}
            onKeyDown={(e) => handleOnKeyPress(e)}
          ></input>
          <div className="modal-footer">
            <button className="no" onClick={close}>
              아니요
            </button>
            <button className="yes" onClick={onClickPwCheck}>
              예
            </button>
          </div>
        </section>
      ) : null}
      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
};

export default CheckModal;
