/* <img src={ImageComponent.이름} className="img_이름"></img> */
// 이런식으로 사용하기

export default {
    default:
        "https://storage.googleapis.com/adpower-files/static/default_thumbnail.jpeg",

    Logo: require("../../assets/Adpower_Logo.png"),
    LogoSvg: require("../../assets/Adpower_Logo.svg"),
    AP_Logo: require("../../assets/AP_Logo.png"),
    Calendar: require("../../assets/Calendar.png"),
    Alarm_On: require("../../assets/Alarm_On.png"),
    Alarm_Off: require("../../assets/Alarm_Off.png"),
    Heart: require("../../assets/Heart.png"),
    Empty_Heart: require("../../assets/Empty_Heart.png"),
    Thumbs_up: require("../../assets/Thumbsup.png"),

    Profile_user0: require("../../assets/Profile_User0.png"),
    Profile_user1: require("../../assets/Profile_User1.png"),
    Profile_user2: require("../../assets/Profile_User2.png"),
    Profile_user3: require("../../assets/Profile_User3.png"),
    Profile_user4: require("../../assets/Profile_User4.png"),
    Profile_user5: require("../../assets/Profile_User5.png"),

    Flag: require("../../assets/Flag.png"),
    Target: require("../../assets/Target.png"),
    Gym: require("../../assets/Gym.png"),
    Tool: require("../../assets/Tool.png"),

    CheckBox_Off: require("../../assets/CheckBox_Off.png"),
    CheckBox_On: require("../../assets/CheckBox_On.png"),
    Check: require("../../assets/Check.png"),
    Check_Red: require("../../assets/Check_Red.png"),
    Dropdown: require("../../assets/Dropdown.png"),
    Dropdown_Up: require("../../assets/Dropdown_Up.png"),
    Search: require("../../assets/Search.png"),
    Comment: require("../../assets/Comment.png"),
    Ar_Right: require("../../assets/Ar_Right.png"),
    Close: require("../../assets/Close.png"),
    Shortcut: require("../../assets/Shortcut.png"),
    Shortcut_Red: require("../../assets/Shortcut_Red.png"),
    Star: require("../../assets/Star.png"),
    Star_White: require("../../assets/Star_White.png"),
    Top: require("../../assets/Top.png"),
    Error: require("../../assets/Error.png"),

    Main3: require("../../assets/Main3.png"),

    AA: require("../../assets/aa.png"),

    Dp_Plan: require("../../assets/Dp_Plan.png"),
    Dp_Copy: require("../../assets/Dp_Copy.png"),
    Dp_Design: require("../../assets/Dp_Design.png"),
    Dp_Film: require("../../assets/Dp_Film.png"),
    Dp_Edu: require("../../assets/Dp_Edu.png"),
    Dp_Coop: require("../../assets/Dp_Coop.png"),
    Dp_Edit: require("../../assets/Dp_Edit.png"),
    Dp_Content: require("../../assets/Dp_Content.png"),
    Apchive_Logo: require("../../assets/Apchive_Logo.png"),
    ExHompage: require("../../assets/ExHompage.png"),

    Medal: require("../../assets/Medal.png"),
    Rocket: require("../../assets/Rocket.png"),
    Link: require("../../assets/Link.png"),

    Project: require("../../assets/Project.png"),
    Apchive: require("../../assets/Apchive.png"),
    Exhibition: require("../../assets/Exhibition.png"),
    PBA: require("../../assets/PBA.png"),

    Instagram: require("../../assets/Instagram.png"),
    Youtube: require("../../assets/Youtube.png"),
    Blog: require("../../assets/Blog.png"),
    Facebook: require("../../assets/Facebook.png"),

    Interview_Off: require("../../assets/Interview_Off.png"),
    Interview_On: require("../../assets/Interview_On.png"),

    Spinner: require("../../assets/Spinner.gif"),
};
