// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import GlobalNavBar from "../navigation/GlobalNavBarComponent";
import { lnbIdList } from "../common/lnbID";
import { useRef } from "react";
import loadingStore from "../../store/loadingStore";
import ImageComponent from "../common/ImageComponent";

export default function AdminYoutubeComponent() {
  const [status, setStatus] = useState(false);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  // 브랜드
  const [youtubeTitle, setYoutubeTitle] = useState();
  const [youtubeDate, setYoutubeDate] = useState();
  const [youtubeLink, setYoutubeLink] = useState();

  const handleTitleValue = (e) => {
    setYoutubeTitle(e.target.value);
  };
  const handleDateValue = (e) => {
    setYoutubeDate(e.target.value);
  };
  const handleLinkValue = (e) => {
    setYoutubeLink(e.target.value);
  };

  const [thumbnailUrl1, setThumbnailUrl1] = useState();

  const [youtubes, setYoutubes] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/admins/youtube/all")
      .then((response) => {
        setYoutubes(response.data.data);
      });
  }, [status]);

  const youtubeList = youtubes.map((youtube) => {
    return (
      <div className="youtube-list" key={youtube.youtube_no}>
        <img src={youtube.thumb}></img>
        <span>{youtube.link}</span>
        <span>{youtube.title}</span>
        <span>{youtube.youtube_date}</span>

        <button id={youtube.youtube_no} onClick={(e) => onClickDelete(e)}>
          X
        </button>
      </div>
    );
  });

  const ThumbnailInput = useRef();
  const onCickThumbnailUpload = () => {
    ThumbnailInput.current.click();
  };

  const ThumbnailOnChange = async (e) => {
    const inputfile = document.getElementById("thumbnail-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    var size = 6;
    if (file) {
      loadingStore.setIstLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_HOST + `/api/files/upload/${size}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setThumbnailUrl1(response.data.fileurl);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingStore.setIstLoading(false);
        });
    }
  };

  // 달력 삽입, 업데이트
  const onClickInsert = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(13);
    setModalEvent(() => {
      const inserts = () => {
        insertBrandEvent();
      };
      return inserts;
    });
  };

  const insertBrandEvent = () => {
    if (thumbnailUrl1 && youtubeTitle && youtubeDate && youtubeLink) {
      axios
        .post(process.env.REACT_APP_API_HOST + "/api/admins/youtube/insert", {
          param: {
            link: youtubeLink,
            title: youtubeTitle,
            thumb: thumbnailUrl1,
            youtube_date: youtubeDate,
          },
        })
        .then((response) => {
          setYoutubeDate();
          setYoutubeLink();
          setYoutubeTitle();
          setThumbnailUrl1();
          setModal(true);
          setModalType(1);
          setContentID(28);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  // 달력 삭제
  const onClickDelete = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(11);
    setModalEvent(() => {
      const deletes = () => {
        deleteBrandEvent(e);
      };
      return deletes;
    });
  };

  const deleteBrandEvent = (e) => {
    axios
      .delete(
        process.env.REACT_APP_API_HOST +
          `/api/admins/youtube/delete/${e.target.id}`
      )
      .then((response) => {
        setModal(true);
        setModalType(1);
        setContentID(26);
        setModalEvent(() => {
          const modalFalse = () => {
            setModal(false);
            setStatus(!status);
          };
          return modalFalse;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="admin-contents">
      <div className="admin-contents-title">유튜브</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-popup-container">
          <p className="admin-popup-explain">
            팝업 이미지는 width:600px로 고정이며 이미지는 한개만 등록
            가능합니다. (상위 5개까지만 노출됨)
          </p>
          <input
            id="thumbnail-input"
            type="file"
            style={{ display: "none" }}
            ref={ThumbnailInput}
            onChange={ThumbnailOnChange}
            accept=".gif, .jpg, .png"
          />
          <div className="admin-popup-bottom">
            <span className="admin-brand-input-label">제목</span>
            <input
              className="admin-brand-input"
              value={youtubeTitle}
              onChange={(e) => {
                handleTitleValue(e);
              }}
            ></input>
            <span className="admin-brand-input-label">링크</span>
            <input
              className="admin-brand-input"
              value={youtubeLink}
              placeholder="QXBr8oGzdYo"
              onChange={(e) => {
                handleLinkValue(e);
              }}
            ></input>
            <span className="admin-brand-input-label">날짜</span>
            <input
              className="admin-brand-input"
              value={youtubeDate}
              placeholder="2024.01.01"
              onChange={(e) => {
                handleDateValue(e);
              }}
            ></input>
          </div>
          <button className="btn-thumbnail" onClick={onCickThumbnailUpload}>
            팝업 이미지 업로드
          </button>
          <img src={thumbnailUrl1 && thumbnailUrl1}></img>
          <button
            onClick={() => {
              onClickInsert();
            }}
          >
            등록
          </button>
          <div>
            <div>서버에 있는 유튜브 데이터 </div>
            {youtubeList}
          </div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
