import React, { useEffect, useRef, useState } from "react";

import "./FooterComponent.css";
function FooterComponent() {
  return (
    <div className="footer">
      <div className="footer-email">
        <span>E-mail. adpower1989@naver.com</span>
      </div>
      <div className="footer-copyright">
        <span>@copyright ADPOWER | 대학생 연합 광고동아리 애드파워 </span>
      </div>
      <div className="footer-copyright">
        <span> ADPOWER 2024 HomePage v2.0.0</span>
      </div>
    </div>
  );
}

export default FooterComponent;
