// import Slick
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageComponent from "../common/ImageComponent";
import "./ArchiveSliderComponent.css";
import axios from "axios";
import { useEffect } from "react";

export default function ArchiveSliderComponent() {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/admins/youtube")
      .then((response) => {
        setLinks(response.data.data);
      });
  }, []);

  const thumbnailList = links.map((link, i) => {
    const src = `https://img.youtube.com/vi/${link.link}/maxresdefault.jpg`;
    const linkto = `https://www.youtube.com/watch?v=${link.link}`;

    return (
      <div key={link.title}>
        <div className="thumbnail-container">
          <img
            className="thumbnail"
            src={link && link.thumb}
            // src={src}
            onClick={() => window.open(linkto, "_blank")}
          ></img>
          <div className="thumbnail-context">
            <div>
              <p>YOUTUBE</p>
              <span className="clamp1">{link.title}</span> <span>{link.youtube_date}</span>
            </div>
            <img
              src={ImageComponent.Shortcut_Red}
              onClick={() => window.open(linkto, "_blank")}
            ></img>
          </div>
        </div>
      </div>
    );
  });

  // 옵션
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        <Slider {...settings}>{thumbnailList}</Slider>
      </div>
    </div>
  );
}
