import "./UserInfoComponent.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import store from "../../store";
import axios from "axios";
import Modal from "../Modal/Modal";

function UserInfoCheckComponent({ setStep }) {
  //Mobx 설정
  const { userStore, loadingStore } = store;

  const [pwValue, setPwValue] = useState("");
  const handlePw = (e) => {
    setPwValue(e.target.value);
  };

  // 모달 관련 함수 선언,
  const [modal, setModal] = useState(false); //모달 온오프
  const [modalType, setModalType] = useState(); //모달 버튼개수
  const [contentid, setContentID] = useState(); //모달이 나타낼 글
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  const onClickPwCheck = () => {
    loadingStore.setIstLoading(true);
    axios
      .post(process.env.REACT_APP_API_HOST + "/api/users/signin", {
        param: {
          id: userStore.checkStatus().userId,
          password: pwValue,
        },
      })
      .then((response) => {
        if (response.data.status === "false") {
          setModal(!modal);
          setModalType(1);
          setContentID(response.data.message);
        } else {
          setStep(1);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => loadingStore.setIstLoading(false));
  };

  return (
    <div className="login-container ">
      <div className="shadow login-box pwCheck">
        <div className="login-center">
          <h1>비밀번호 확인</h1>
          <input
            type={"password"}
            className="login-input"
            placeholder="비밀번호"
            value={pwValue}
            onChange={(e) => {
              handlePw(e);
            }}
          ></input>
          <button
            className="btn-write btn-login"
            onClick={() => {
              onClickPwCheck();
            }}
          >
            비밀번호 확인
          </button>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}

export default observer(UserInfoCheckComponent);
