import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // css import
import "./CalendarComponent.css";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function CalendarComponent(props) {
  const nowParams = useParams();
  const [value, onChange] = useState(new Date());
  const handleChange = (value) => {
    onChange(value);
    if (props.setCalendarDate) {
      props.setCalendarDate(dayjs(value).format("YYYY-MM-DD"));
      props.setCalendarTitle('');
      props.setCalendarStart('');
      props.setCalendarEnd('');
      props.setType("등록");
      props.setEventId();
    }
  };
  const [mark, setMark] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // 정보를 가져올 때 로딩 상태를 보여주기 위한 state

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/calendars/")
      .then((response) => {
        setMark(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props]);

  const getEvents = (value) => {
    const a = mark.filter(
      (x) => x.cal_date === dayjs(value).format("YYYY-MM-DD")
    );
    const list = a.map((event) => {
      return (
        <div
          className={
            nowParams.Path === "Calendar"
              ? "events-list pointer"
              : "events-list"
          }
          key={event.cal_title + event.cal_date}
          onClick={() => {
            if (nowParams.Path === "Calendar") {
              props.setCalendarTitle(event.cal_title);
              props.setCalendarDate(event.cal_date);
              props.setCalendarStart(event.cal_start_time);
              props.setCalendarEnd(event.cal_end_time);
              props.setEventId(event.cal_no);
              props.setType("수정");
            }
          }}
        >
          <div className="dot"></div>
          <span className="event-time">
            {event.cal_start_time} - {event.cal_end_time}
          </span>
          <span className="event-name">{event.cal_title} </span>
        </div>
      );
    });
    return list;
  };
  return (
    <div className={props.className}>
      <Calendar
        onChange={handleChange}
        value={value}
        calendarType="US"
        prev2Label={null}
        next2Label={null}
        formatMonthYear={(locale, date) => dayjs(date).format("MMMM")}
        formatShortWeekday={(locale, date) => dayjs(date).format("dd")[0]}
        formatDay={(locale, date) => dayjs(date).format("D")}
        showNeighboringMonth={false}
        tileContent={({ date, view }) => {
          // 날짜 타일에 컨텐츠 추가하기 (html 태그)
          // 추가할 html 태그를 변수 초기화
          let dots = [];
          // 현재 날짜가 post 작성한 날짜 배열(mark)에 있다면, dot div 추가
          if (
            mark.find((x) => x.cal_date === dayjs(date).format("YYYY-MM-DD"))
          ) {
            // let result = mark.filter(
            //   (x) => x.date === dayjs(date).format("YYYY-MM-DD")
            // ).length;
            // for (let i = 0; i < result; i++) {
            dots.push(<div className="dot"></div>);
            // }
          }
          // 다른 조건을 주어서 html.push 에 추가적인 html 태그를 적용할 수 있음.
          return (
            <>
              <div className="absolute">
                <div className="dot-list">{dots}</div>
              </div>
            </>
          );
        }}
      />
      <div className="calendar-events-list">
        <hr></hr>
        <p className="checkDay"> {dayjs(value).format("DD dddd")}</p>
        {isLoading ? null : <>{getEvents(value)}</>}
      </div>
    </div>
  );
}
