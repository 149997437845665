import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../Modal/Modal";
// import isLogin from "./isLogin";
import store from "../../store/index";
import getAuthorization from "./getAuthorization";
import NullPage from "../pages/NullPage";

/**
 * 로그인 했을 때 가능
 */
function PrivateRoute({ component, content }) {
  const navigate = useNavigate();
  const nowParams = useParams(); //주소에서 /:Category/:Sub

  const { userStore } = store;
  const [isLoading, setIsLoading] = useState(true); // 정보를 가져올 때 로딩 상태를 보여주기 위한 state

  // 권한
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    getAuthorization(token, setIsLoading);
  }, []);

  // isLoading이 true일 동안 로딩 중이라는 화면을 보여줍니다.
  if (isLoading) {
    return <NullPage />;
  }

  return userStore.isLogin || nowParams.Sub === "Q&A" ? (
    <>{component}</>
  ) : (
    <Modal
      open={true}
      type={1}
      content={content}
      modalEvent={() => {
        navigate(-1);
      }}
    />
  );
}

export default PrivateRoute;
