import MainOneComponent from "../main/MainOneComponent";
import MainThreeComponent from "../main/MainThreeComponent";
import MainTwoComponent from "../main/MainTwoComponent";
import getAuthorization from "../utils/getAuthorization";
import { useEffect, useState } from "react";
import store from "../../store/index";
import NullPage from "./NullPage";
import PopUpComponent from "../common/PopUpComponent";
import axios from "axios";

function MainPage() {
  const { userStore } = store;
  const [isLoading, setIsLoading] = useState(true); // 정보를 가져올 때 로딩 상태를 보여주기 위한 state

  // 권한
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    getAuthorization(token, setIsLoading);

    if (navigator.userAgent.includes("KAKAOTALK")) {
      alert(
        "해당 브라우저에서는\n 서비스 이용이 제한될 수 있습니다.\n 원활한 서비스 이용을 위해\n 다른 브라우저를 통해 접속해주세요."
      );
    }
  }, [userStore]);

  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/admins/popup")
      .then((response) => {
        setData(response.data.data[0]);
      });
  }, []);
  const [popup, setPopup] = useState(false);
  const today = new Date();
  const saveDay = today.getDate();
  const storageDay = parseInt(localStorage.getItem("today"));
  useEffect(() => {
    if (saveDay === storageDay) {
      setPopup(false);
    } else {
      setPopup(true);
    }
  }, []);
  // isLoading이 true일 동안 로딩 중이라는 화면을 보여줍니다.
  if (isLoading) {
    return <NullPage />;
  }
  return (
    <div>
      {popup && data && data.pop_set === 1 ? (
        <PopUpComponent data={data} setPopup={setPopup} />
      ) : null}
      <MainOneComponent />
      <MainTwoComponent />
      <MainThreeComponent />
    </div>
  );
}

export default MainPage;
