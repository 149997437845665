import axios from "axios";
import { React, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import { lnbIdList } from "../common/lnbID";
import PagingComponent from "../post/PagingComponent";

import "./ExhibitionComponent.css";

export default function ExhibitionComponent() {
  const nowParams = useParams();
  //현재 페이지 설정 쿼리스트링
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const currentPage = searchParams.get("currentPage");
  const currentType = searchParams.get("type");

  //페이지네이션 된 데이터 가져오기
  const [posts, setPosts] = useState([]);
  const [totalPage, setTotalPage] = useState([[]]);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/api/posts/pagination/" +
          lnbIdList[nowParams.Sub],
        {
          params: {
            search: search,
            page: currentPage,
            perPage: 6,
          },
        }
      )
      .then((response) => {
        setPosts(response.data.data.posts);
        const pageArray = Array.from(
          { length: response.data.data.totalPage },
          (v, i) => i + 1
        );
        const pageArr = [];
        for (let i = 0; i < pageArray.length; i += 5) {
          pageArr.push(pageArray.slice(i, i + 5));
        }
        // 페이지 나누기..
        if (pageArr.length !== 0) {
          setTotalPage(pageArr);
        } else {
          setTotalPage([[]]);
        }
      });
  }, [nowParams, currentPage, currentType]);

  // 모두보기
  function onclickShowAll() {
    searchParams.set("type", "All");
    setSearchParams(searchParams);
  }

  const exhibitionAll = posts.map((eh) => {
    return (
      <Link
        to={`post/?postNo=${eh.post_no}&search=${
          search || ""
        }&currentPage=${currentPage}`}
      >
        <div className="exhibition-each">
          <img src={eh.post_thumb1}></img>
        </div>
      </Link>
    );
  });

  const [firstPost, setFirstPost] = useState([]);
  const [otherPost, setOtherPost] = useState([]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/api/posts/findpost/bycatno/" +
          lnbIdList[nowParams.Sub]
      )
      .then((response) => {
        setFirstPost([response.data.data[0]]);
        setOtherPost(response.data.data.slice(1));
      });
  }, [nowParams, currentPage, currentType]);

  // FIXME: 전체글이 나오도록 하는 API필요, 현재 페이지네이션된거라 개수 제한됨
  //가장최신글 크게
  const exhibitionFirst = firstPost.map((eh) => {
    if (eh) {
      return (
        <Link
          to={`post/?postNo=${eh.post_no}&search=${search || ""}&currentPage=1`}
          className="linkto"
        >
          <img src={eh.post_thumb1}></img>
        </Link>
      );
    }
  });

  //지난 전시(첫번째꺼 빼고 작게)
  const exhibitionList = otherPost.map((eh) => {
    return (
      <Link
        to={`post/?postNo=${eh.post_no}&search=${search || ""}&currentPage=1`}
        className="linkto"
      >
        <div className="exhibition-before-container">
          <img src={eh.post_thumb1}></img>
        </div>
      </Link>
    );
  });
  // 옵션
  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="exhibition">
        {currentType !== "All" ? (
          //첫화면
          <>
            {posts.length > 0 ? (
              <div className="exhibition-main">{exhibitionFirst}</div>
            ) : (
              <div className="no_data">등록된 게시글이 없습니다.</div>
            )}

            {posts.length > 1 ? (
              <>
                <div className="exhibition-before">
                  <div className="exhibition-before-top">
                    <span>지난 전시</span>
                    <div>
                      <span
                        className="exhibition-showall"
                        onClick={() => {
                          onclickShowAll();
                        }}
                      >
                        모두 보기
                      </span>
                      <span> &gt;</span>
                    </div>
                  </div>

                  <div className="exhibition-before-slider">
                    <Slider {...settings}>{exhibitionList}</Slider>
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>
            {posts.length > 0 ? (
              <div className="exhibition-grid">{exhibitionAll}</div>
            ) : (
              <div className="no_data">등록된 게시글이 없습니다.</div>
            )}
          </>
        )}
      </div>
      <div className="forum-bottom">
        <Link to={`/${nowParams.Category}/${nowParams.Sub}/editor`}>
          <button className="btn-write">글쓰기</button>
        </Link>
      </div>

      {currentType === "All" ? (
        <PagingComponent
          currentPage={currentPage}
          totalPage={totalPage}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        ></PagingComponent>
      ) : null}
    </>
  );
}
