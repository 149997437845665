import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import ExhibitionComponent from "./ExhibitionComponent";
import ForumGridComponent from "./ForumGridComponent";
import ForumListComponent from "./ForumListComponent";
import RecruitComponent from "./RecruitComponent";
import "./ForumComponent.css";
import InfoComponent from "../info/InfoComponent";

// 게시판 컴포넌트
function ForumComponent() {
  const nowParams = useParams(); //주소에서 /:Category/:Sub
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get("type");
  //부서별 이미지
  const DpMap = {
    기획부: {
      img: ImageComponent.Dp_Plan,
      url: "https://www.instagram.com/adpower.planning/",
    },
    카피부: {
      img: ImageComponent.Dp_Copy,
      url: "https://www.instagram.com/adpower.copy/",
    },
    디자인부: {
      img: ImageComponent.Dp_Design,
      url: "https://www.instagram.com/adpower.design/",
    },
    영상부: {
      img: ImageComponent.Dp_Film,
      url: "https://www.instagram.com/adpower.film/",
    },
    교육팀: {
      img: ImageComponent.Dp_Edu,
      url: "https://www.instagram.com/adpower.education/",
    },
    대외협력팀: {
      img: ImageComponent.Dp_Coop,
      url: "https://www.instagram.com/adpower.cooperation/",
    },
    편집팀: {
      img: ImageComponent.Dp_Edit,
      url: "https://www.instagram.com/adpower.edit/",
    },
    콘텐츠팀: {
      img: ImageComponent.Dp_Content,
      url: "https://www.instagram.com/adpower.contents/",
    },
    애카이브: {
      img: ImageComponent.Apchive_Logo,
      url: "https://www.instagram.com/ap_chive/ ",
    },
  };

  const getDpImg = () => {
    if (
      nowParams.Category === "부서" ||
      nowParams.Category === "운영팀" ||
      nowParams.Sub === "애카이브"
    )
      return (
        <div
          className="forum-dp"
          onClick={() => {
            window.open(DpMap[nowParams.Sub].url, "_blank");
          }}
        >
          <img src={DpMap[nowParams.Sub].img} className="img_dp" />
        </div>
      );
  };

  // 검색어 입력
  const [textValue, setTextValue] = useState(""); //제목
  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };
  useEffect(() => {
    setTextValue("");
  }, [nowParams.Sub]);

  //검색
  const onClickSearch = () => {
    if (nowParams.Sub === "전시회")
      navigate(`?search=${textValue}&currentPage=1&type=${currentType}`);
    else navigate(`?search=${textValue}&currentPage=1`);
  };

  // 엔터
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };

  return (
    <div className="forum">
      {getDpImg()}

      {
        //게시글로 쓸 애들
        nowParams.Sub !== "소개" &&
        nowParams.Sub !== "BI" &&
        nowParams.Sub !== "연혁" &&
        nowParams.Sub !== "운영위원회" &&
        nowParams.Sub !== "회칙" &&
        nowParams.Sub !== "모집 요강" ? (
          //이상한 모양애들
          (nowParams.Sub !== "전시회") & (nowParams.Sub !== "모집 공지사항") ? (
            // 나머지 게시판
            <>
              <div className="forum-search">
                <div className="shadow">
                  <input
                    className="board-title-input"
                    type="text"
                    placeholder="검색어를 입력하세요"
                    value={textValue}
                    onChange={(e) => handleSetValue(e)}
                    onKeyDown={(e) => handleOnKeyPress(e)}
                  ></input>

                  <img
                    src={ImageComponent.Search}
                    className="pointer"
                    onClick={onClickSearch}
                  ></img>
                </div>
              </div>
              {
                //그리드 인애들
                nowParams.Sub === "집회 리뷰" ||
                nowParams.Sub === "전체스터디 리뷰" ||
                nowParams.Sub === "애카이브" ||
                nowParams.Sub === "PBA" ? (
                  <ForumGridComponent />
                ) : (
                  //나머지 리스트
                  <ForumListComponent />
                )
              }
            </>
          ) : (
            // <>이상한 레이아웃 페이지 (전시회, 모집 공지사항)</>
            <>
              {nowParams.Sub === "전시회" ? (
                //전시회
                <>
                  <div className="forum-search">
                    <div className="shadow">
                      <input
                        className="board-title-input"
                        type="text"
                        placeholder="검색어를 입력하세요"
                        value={textValue}
                        onChange={(e) => handleSetValue(e)}
                      ></input>
                      <img
                        src={ImageComponent.Search}
                        className="pointer"
                        onClick={onClickSearch}
                        onKeyDown={handleOnKeyPress}
                      ></img>
                    </div>
                  </div>
                  <ExhibitionComponent />
                </>
              ) : (
                //모집 공지사항
                <RecruitComponent />
              )}
            </>
          )
        ) : (
          <InfoComponent />
        )
      }
    </div>
  );
}

export default ForumComponent;
