import "./MyPageComponent.css";
import React, { useEffect, useState } from "react";
import ImageComponent from "../common/ImageComponent";
import ForumListComponent from "../forum/ForumListComponent";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import store from "../../store";

function MyPageComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // 검색어 입력
  const [searchValue, setSearchValue] = useState(""); //검색어
  const handleSetValue = (e) => {
    setSearchValue(e.target.value);
  };

  //작성글 좋아요한글
  const [type, setType] = useState(searchParams.get("type"));
  useEffect(() => {
    setType(searchParams.get("type"));
  }, [useSearchParams()]);

  //검색
  const onClickSearch = () => {
    navigate(`?search=${searchValue}&type=${type}&currentPage=1`);
  };

  // 엔터
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };

  const { userStore } = store;
  const uClass = userStore.checkStatus().userClass;

  return (
    <div className="mypage">
      <div className="mypage-header">
        <div className="mypage-profile shadow">
          <div className="profile-left">
            <div className="profile-left-top">
              <img
                src={ImageComponent["Profile_user" + uClass]}
                className="img_profile"
              ></img>
              <div>
                <span>{userStore.checkStatus().userName}</span>
                <span>{userStore.checkStatus().userId}</span>
              </div>
            </div>
            <div className="profile-left-bottom">
              <span>{userStore.checkStatus().userDep}</span>
              <span>|</span>
              <span>{userStore.checkStatus().userDepNo}</span>
              <span>|</span>
              <span>{gradeList[userStore.checkStatus().userClass]}</span>
              <button
                onClick={() => {
                  localStorage.removeItem("jwtToken");
                  userStore.setLogout();
                  navigate("/");
                  window.location.reload();
                }}
              >
                로그아웃
              </button>
            </div>
          </div>
          <div className="profile-right">
            {uClass === 5 && (
              <Link
                to="/Admin/Users?search=&currentPage=1"
                className="profile-right-btn"
              >
                관리자페이지
              </Link>
            )}
            <Link
              to="/Mypage/UserInfo?type=signout"
              className="profile-right-btn"
            >
              회원탈퇴
            </Link>
            <Link
              to="/Mypage/UserInfo?type=update"
              className="profile-right-btn"
            >
              정보수정
            </Link>
          </div>
        </div>
      </div>

      <div className="forum-search">
        <span
          className={type === "write" ? "checked" : ""}
          onClick={() => {
            searchParams.set("type", "write");
            searchParams.set("search", "");
            setSearchParams(searchParams);
            setSearchValue("");
          }}
        >
          작성글
        </span>
        <span
          className={type === "like" ? "checked" : ""}
          onClick={() => {
            searchParams.set("type", "like");
            searchParams.set("search", "");
            setSearchParams(searchParams);
            setSearchValue("");
          }}
        >
          좋아요한 글
        </span>
        <div className="shadow">
          <input
            className="board-title-input"
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchValue}
            onChange={(e) => handleSetValue(e)}
            onKeyDown={(e) => {
              handleOnKeyPress(e);
            }}
          ></input>
          <img
            src={ImageComponent.Search}
            className="pointer"
            onClick={onClickSearch}
          ></img>
        </div>
      </div>

      <ForumListComponent />
    </div>
  );
}

export default observer(MyPageComponent);

export const gradeList = [
  "비애파리안",
  "선배 애파리안",
  "현역 애파리안",
  "역대 운영위원회",
  "현대 운영위원회",
  "관리자",
];
