import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import store from "../../store";
import Modal from "../Modal/Modal";
import NullPage from "../pages/NullPage";
import getAuthorization from "./getAuthorization";

/**
 * 글쓰기 권한
 */
function PostRoute({ component, content }) {
  const navigate = useNavigate();
  const nowParams = useParams(); //주소에서 /:Category/:Sub
  const location = useLocation();
  const { userStore } = store;
  const [isLoading, setIsLoading] = useState(true); // 정보를 가져올 때 로딩 상태를 보여주기 위한 state

  // 권한
  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    getAuthorization(token, setIsLoading);
  }, [userStore]);

  // isLoading이 true일 동안 로딩 중이라는 화면을 보여줍니다.
  if (isLoading) {
    return <NullPage />;
  }

  if (nowParams.Sub === "운영 DB" || location.pathname === "/Admin") {
    // 유저클래스 5이상 접근가능
    if (userStore.userClass >= 4) {
      return component;
    }
  } else if (nowParams.Sub === "Q&A") {
    // 비로그인 + 123456
    return component;
  } else {
    if (userStore.userClass >= 1) {
      return component;
    }
  }

  //조건문 안걸리면 해당 안되는거
  return (
    <Modal
      open={true}
      type={1}
      content={content}
      modalEvent={() => {
        navigate(-1);
      }}
    />
  );
}

export default PostRoute;
