import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";

// import "./MainComponent.css";
export default function CardDepartmentComponent() {
    const navigate = useNavigate();
    const initial = {
        기획부: "P",
        카피부: "C",
        디자인부: "D",
        영상부: "F",
    };

    const [departments, setDepartments] = useState([[]]);
    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_HOST + "/api/mainpages/1/2")
            .then((response) => {
                setDepartments(response.data.data);
            });
    }, []);

    return (
        <ul className="main1-departments shadow">
            {departments.map((department, index) => (
                <li
                    key={index}
                    className="main1-departments-dp"
                    onClick={() => {
                        navigate(
                            `/부서/${department.cat_name}/post?postNo=${department.post_no}&currentPage=1`
                        );
                    }}
                >
                    <p className="dp-initial">{initial[department.cat_name]}</p>
                    {department.NEW === "True" ? (
                        <p className="dp-new">New!</p>
                    ) : (
                        <p className="dp-new hidden">New!</p>
                    )}
                    <div>
                        <span className="dp-name">{department.cat_name}</span>
                        <img
                            src={ImageComponent.Ar_Right}
                            className="img_arright"
                        ></img>
                    </div>
                    <span className="dp-title">{department.title}</span>
                </li>
            ))}
        </ul>
    );
}
