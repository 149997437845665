import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import store from "../../store";

function WriteCommentComponent({
    reload,
    setReload,
    postNo,
    modal,
    setModal,
    setModalType,
    setContentID,
    display,
    parentNo,
    commentNo, //
    type,
    commentValue, //수정용
}) {
    // 로딩

    //Mobx
    const { userStore, loadingStore } = store;

    //댓글달기
    const [textValue, setTextValue] = useState(commentValue || "");

    useEffect(() => {
        if (textValue.length > 200) setTextValue(textValue.substr(0, 200));
    }, [textValue]);

    const handleSetValue = (e) => {
        if (textValue.length <= 200) setTextValue(e.target.value);
        if (textValue.length > 200) setTextValue(textValue.substr(0, 200));
    };

    //답글=>빈칸, 수정=>이전내용
    useEffect(() => {
        if (type === "edit") {
            setTextValue(commentValue);
        } else {
            setTextValue("");
        }
    }, [type]);

    //등록누르면 댓글 작성창닫기
    const checkFalse = () => {
        const checkboxes = document.getElementsByName("checkbox");
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    };

    //등록버튼 누르면
    function onClickComment() {
        if (textValue.length > 0) {
            loadingStore.setIstLoading(true);
            if (type === "edit") {
                axios
                    .put(
                        process.env.REACT_APP_API_HOST + "/api/comments/update",
                        {
                            param: [{ comment_content: textValue }, commentNo],
                        }
                    )
                    .then((response) => {
                        setTextValue("");
                        setReload(!reload);
                        checkFalse();
                    })
                    .catch(function (error) {
                        console.log(error.config);
                    })
                    .finally(() => loadingStore.setIstLoading(false));
            } else {
                axios
                    .post(
                        process.env.REACT_APP_API_HOST + "/api/comments/insert",
                        {
                            param: {
                                post_no: postNo,
                                user_no: userStore.checkStatus().userNo,
                                comment_content: textValue,
                                comment_parent_no: parentNo || null,
                            },
                        }
                    )
                    .then((response) => {
                        setTextValue("");
                        setReload(!reload);
                        checkFalse();
                    })
                    .catch(function (error) {
                        console.log(error.config);
                    })
                    .finally(() => loadingStore.setIstLoading(false));
            }
        } else {
            setModalType(1);
            setContentID(9);
            setModal(!modal);
        }
    }
    return (
        <div className={`comment-box shadow comment-${display}`}>
            {type === "reply" ? (
                <div className="reply-icon">
                    <div className="reply-rotate"></div>
                </div>
            ) : null}
            <div className="comment-write-box write">
                <textarea
                    className="comment-write"
                    placeholder="댓글을 입력하세요"
                    value={textValue}
                    onFocus={(e) => {
                        e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                        e.target.placeholder = "댓글을 입력하세요";
                    }}
                    onChange={(e) => {
                        handleSetValue(e);
                    }}
                ></textarea>
                <div className="comment-length">{textValue.length}</div>
                <div> / 200</div>
            </div>
            <button
                className="btn-write"
                onClick={() => {
                    onClickComment();
                }}
            >
                등록
            </button>
        </div>
    );
}

export default observer(WriteCommentComponent);
