import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.toLowerCase().includes("admin"))
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  return null;
}
