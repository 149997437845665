import PostComponent from "../post/PostComponent";

function PostPage() {
  return (
    <div>
      {/* <LocalNavBarComponent /> */}
      <PostComponent />
    </div>
  );
}

export default PostPage;
