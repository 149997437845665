// import Slick
import React, { useState } from "react";
import "./PopUp.css";
export default function PopUpComponent({ data, setPopup }) {
  const today = new Date();
  const saveDay = today.getDate();

  return (
    <div className="popup-container">
      <div className="popup-img-container">
        <img className="popup-img" src={data.pop_image_url}></img>
      </div>
      <div className="popup-bottom">
        <span
          className="popup-today"
          onClick={() => {
            localStorage.setItem("today", saveDay);
            setPopup(false);
          }}
        >
          오늘 하루동안 열지 않기
        </span>
        <span
          className="popup-close"
          onClick={() => {
            setPopup(false);
          }}
        >
          닫기
        </span>
      </div>
    </div>
  );
}
