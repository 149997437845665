export default function ConvertCommentDateStyle(dateString) {
    const date = new Date(dateString);
    const now = new Date();

    const diffInMs = now - date;
    const diffInMin = Math.floor(diffInMs / (1000 * 60));

    if (diffInMin < 5) {
        return "방금";
    } else if (isSameDay(date, now)) {
        return `오늘 ${formatTime(date)}`;
    } else {
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        if (isSameDay(date, yesterday)) {
            return `어제 ${formatTime(date)}`;
        } else if (isSameYear(date, now)) {
            return `${date.getMonth() + 1}월 ${date.getDate()}일 ${formatTime(
                date
            )}`;
        } else {
            return `${date.getFullYear()}. ${
                date.getMonth() + 1
            }. ${date.getDate()}. ${formatTime(date)}`;
        }
    }
}

function isSameDay(date1, date2) {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

function isSameYear(date1, date2) {
    return date1.getFullYear() === date2.getFullYear();
}

function formatTime(date) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
}
