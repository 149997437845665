// import Slick
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Modal from "../../Modal/Modal";
import { useRef } from "react";
import loadingStore from "../../../store/loadingStore";
import ImageComponent from "../../common/ImageComponent";

export default function AdminNoticeComponent() {
  const [status, setStatus] = useState(false);
  // 모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(1);
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  //사용자가 업로드할 이미지
  const [imageUrl, setImageUrl] = useState("");
  const [step, setStep] = useState();
  const [fileUrl, setFileUrl] = useState();

  // 서버에 저장되어있는 파일
  const [serverData, setServerData] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_HOST + "/api/adpowerinfo/recruitadmin")
      .then((response) => {
        console.log(response.data);
        setServerData(response.data.data);
      });
  }, [status]);

  const FileInput = useRef();
  const onClickFileUpload = () => {
    FileInput.current.click();
  };
  const FileOnChange = async (e) => {
    const inputfile = document.getElementById("file-input");
    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    if (file) {
      loadingStore.setIstLoading(true);
      await axios
        .post(process.env.REACT_APP_API_HOST + `/api/files/files`, formData, {
          headers: {
            "Content-Type": "undefined",
          },
        })
        .then((response) => {
          setFileUrl(response.data.fileurl);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingStore.setIstLoading(false);
        });
    }
  };

  const ImageInput = useRef();
  const onClickImageUpload = () => {
    ImageInput.current.click();
  };

  const ImageOnChange = async (e) => {
    const inputfile = document.getElementById("image-input");

    const file = inputfile.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let size = 0;
    if (file) {
      loadingStore.setIstLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_HOST + `/api/files/upload/${size}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setImageUrl(response.data.fileurl);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingStore.setIstLoading(false);
        });
    }
  };

  // 업데이트
  const onClickUpdate = (e) => {
    setModal(true);
    setModalType(2);
    setContentID(13);
    setModalEvent(() => {
      const update = () => {
        updateEvent();
      };
      return update;
    });
  };

  const updateEvent = () => {
    if (imageUrl && step) {
      axios
        .put(process.env.REACT_APP_API_HOST + "/api/adpowerinfo/update", {
          param: [
            {
              ap_name: "모집 공지사항",
              ap_image_url: imageUrl,
              ap_file_url: fileUrl,
              ap_open: checked ? 1 : 0,
            },
            Number(step) + 11,
          ],
        })
        .then((response) => {
          setImageUrl(undefined);
          setStep(undefined);
          setModal(true);
          setModalType(1);
          setContentID(27);
          setModalEvent(() => {
            const close = () => {
              setStatus(!status);
              setModal(false);
            };
            return close;
          });
        })
        .catch((error) => {
          setModal(true);
          setModalType(1);
          setContentID(29);
          setModalEvent(() => {
            const close = () => {
              setModal(false);
            };
            return close;
          });
        });
    } else {
      setModal(true);
      setModalType(1);
      setContentID(4);
      setModalEvent(() => {
        const close = () => {
          setModal(false);
        };
        return close;
      });
    }
  };

  // 삽입
  const onClickChangeOpen = (note, isOpen) => {
    setModal(true);
    setModalType(2);
    setContentID(13);

    setModalEvent(() => {
      const update = () => {
        updateOpen(note, isOpen);
      };
      return update;
    });
  };

  const updateOpen = (note, isOpen) => {
    axios
      .put(process.env.REACT_APP_API_HOST + "/api/adpowerinfo/update", {
        param: [
          {
            ap_open: isOpen === 1 ? 0 : 1,
          },
          note,
        ],
      })
      .then((response) => {
        setImageUrl(undefined);
        setStep(undefined);
        setModal(true);
        setModalType(1);
        setContentID(27);
        setModalEvent(() => {
          const close = () => {
            setStatus(!status);
            setModal(false);
          };
          return close;
        });
      })
      .catch((error) => {
        setModal(true);
        setModalType(1);
        setContentID(29);
        setModalEvent(() => {
          const close = () => {
            setModal(false);
          };
          return close;
        });
      });
  };
  const nameList = ["지원 방식 안내서", "면접 안내서", "최종 합격자 발표"];
  const serverList = serverData.map((data) => {
    return (
      <div className="admin-server-data">
        <p>{nameList[data.ap_note]}</p>
        <p
          onClick={() => {
            window.open(data.ap_image_url);
          }}
        >
          {data.ap_image_url}
        </p>
        <p>파일명 :{data.ap_file_url && data.ap_file_url.split("/")[3]} </p>
        <p>게시 여부 : {data.ap_open === 1 ? "O" : "X"}</p>
        <button
          onClick={() => {
            onClickChangeOpen(data.ap_no, data.ap_open);
          }}
        >
          게시여부 바꾸기
        </button>
      </div>
    );
  });
  const handleRadio = (e) => {
    setStep(e.target.value);
  };

  const [checked, setChecked] = useState(false);
  const checkHandler = (e) => {
    setChecked(!checked);
  };
  return (
    <div className="admin-contents">
      <div className="admin-contents-title">모집 공지사항</div>
      <div className="admin-hearder--2 shadow">
        <div className="admin-popup-container">
          <p className="admin-popup-explain"></p>
          <input
            type="radio"
            name="filter"
            value={0}
            onChange={(e) => {
              handleRadio(e);
            }}
          ></input>
          <span>지원 방식 안내서</span>
          <input
            type="radio"
            name="filter"
            value={1}
            onChange={(e) => {
              handleRadio(e);
            }}
          ></input>
          <span>면접 안내서</span>
          <input
            type="radio"
            name="filter"
            value={2}
            onChange={(e) => {
              handleRadio(e);
            }}
          ></input>
          <span>최종 합격자 발표</span>
          <input
            id="image-input"
            type="file"
            style={{ display: "none" }}
            ref={ImageInput}
            onChange={ImageOnChange}
            accept=".gif, .jpg, .png"
          />
          <button className="btn-thumbnail" onClick={onClickImageUpload}>
            이미지 업로드
          </button>
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            ref={FileInput}
            onChange={FileOnChange}
            accept=".zip, .pdf"
          />
          <button className="btn-thumbnail" onClick={onClickFileUpload}>
            파일 업로드
          </button>
          <input
            type={"checkbox"}
            id="chk"
            onChange={(e) => checkHandler(e)}
          ></input>
          <label htmlFor="chk">
            {checked ? (
              <img
                src={ImageComponent.CheckBox_On}
                className="img_checkBox"
              ></img>
            ) : (
              <img
                src={ImageComponent.CheckBox_Off}
                className="img_checkBox"
              ></img>
            )}
          </label>
          <label htmlFor="chk">
            <span className="chk">게시 여부</span>
          </label>
          <button
            onClick={() => {
              onClickUpdate();
            }}
          >
            수정
          </button>
          <p>업로드 한 이미지확인</p>
          <img src={imageUrl}></img>
          <p>업로드한 파일 명 : {fileUrl}</p>
          <div>
            <hr />
            <p>현재 서버에 있는 이미지 (각 링크 클릭하여 확인): </p>
            {serverList}
          </div>
        </div>
      </div>

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}
