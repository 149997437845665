import { makeAutoObservable } from "mobx";

class User {
  isLogin = false;
  userClass = null;
  userDep = null;
  userDepNo = null;
  userEmail = null;
  userId = null;
  userName = null;
  userNick = null;
  userNo = null;

  constructor() {
    makeAutoObservable(this);
  }

  checkStatus() {
    return {
      isLogin: this.isLogin,
      userClass: this.userClass,
      userDep: this.userDep,
      userDepNo: this.userDepNo,
      userEmail: this.userEmail,
      userEmail: this.userEmail,
      userId: this.userId,
      userName: this.userName,
      userNick: this.userNick,
      userNo: this.userNo,
    };
  }

  setLoggedInfo(resultData) {
    this.isLogin = true;
    this.userClass = resultData.user_class;
    this.userDep = resultData.user_department;
    this.userDepNo = resultData.user_department_number;
    this.userEmail = resultData.user_email;
    this.userId = resultData.user_id;
    this.userName = resultData.user_name;
    this.userNick = resultData.user_nick;
    this.userNo = resultData.user_no;
  }

  setLogout() {
    this.isLogin = false;
    this.userClass = null;
    this.userDep = null;
    this.userDepNo = null;
    this.userEmail = null;
    this.userId = null;
    this.userName = null;
    this.userNick = null;
    this.userNo = null;
  }
}

const userStore = new User();

export default userStore;
