import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import store from "../../store";
import ImageComponent from "../common/ImageComponent";
import AlarmComponent from "../dropdown/AlarmComponent";
import CalendarComponent from "../dropdown/CalendarComponent";
import ProfileComponent from "../dropdown/ProfileComponent";

import "./HeaderComponent.css";
import GlobalNavMenuMobile from "./GlobalNavMenuMobile";
import { gradeList } from "../mypage/MyPageComponent";

function Header() {
  const { userStore } = store;
  const nowLocation = useLocation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(false);
  function onClickProfile(e) {
    if (
      e.target.parentElement.className == "header-profile-none" ||
      e.target.parentElement.className == "profile-bottom"
    ) {
      setProfile(!profile);
    }
  }
  const [alarm, setAlarm] = useState(false);
  function onClickCAlarm() {
    setAlarm(!alarm);
  }

  const [calendar, setCalendar] = useState(false);
  function onClickCalendar() {
    setCalendar(!calendar);
  }

  const [alarms, setAlarms] = useState([]); //데이터
  const [reload, setReload] = useState(false);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_HOST +
          "/api/notifications/" +
          userStore.checkStatus().userNo
      )
      .then((response) => {
        setAlarms(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [nowLocation, reload, alarm]);

  // 햄버거 메뉴 모달
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  function handleHamburgerModal() {
    setHamburgerMenu((prev) => !prev);
  }
  useEffect(() => {
    if (hamburgerMenu) {
      // 메뉴가 열린 경우, 스크롤을 막음
      document.body.style.overflow = "hidden";
    } else {
      // 메뉴가 닫힌 경우, 스크롤을 다시 활성화
      document.body.style.overflow = "auto";
    }
  }, [hamburgerMenu]);

  const refP = useRef(); //프로필 외부영역클릭
  const refP2 = useRef(); //햄버거 프로필 외부영역클릭
  const refA = useRef(); //알림 외부영역클릭
  const refA2 = useRef(); //햄버거 알림 외부영역클릭
  const refC = useRef(); //캘린더 외부영역클릭
  const refC2 = useRef(); //햄버거 캘린더 외부영역클릭

  const handleClickOutSide = (e) => {
    const mobileHeader =
      document.getElementsByClassName("header-wrap-mobile")[0];
    const isMobile =
      window.getComputedStyle(mobileHeader).getPropertyValue("display") ===
      "flex";
    if (!isMobile) {
      // 웹
      if (profile && !refP.current.contains(e.target)) {
        setProfile(!profile);
      } else if (alarm && !refA.current.contains(e.target)) {
        setAlarm(!alarm);
      } else if (calendar && !refC.current.contains(e.target)) {
        setCalendar(!calendar);
      }
    } else {
      // 모바일
      if (profile && !refP2.current.contains(e.target)) {
        setProfile(!profile);
      } else if (alarm && !refA2.current.contains(e.target)) {
        setAlarm(!alarm);
      } else if (calendar && !refC2.current.contains(e.target)) {
        setCalendar(!calendar);
      }
    }
  };
  useEffect(() => {
    if (profile) document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  });

  useEffect(() => {
    if (alarm) document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  });

  useEffect(() => {
    if (calendar) document.addEventListener("click", handleClickOutSide);
    return () => {
      document.removeEventListener("click", handleClickOutSide);
    };
  });

  return (
    <div className="header">
      {/* 좌측 */}
      <div className="header-logo">
        <Link to="/">
          <img src={ImageComponent.LogoSvg.default} className="img_logo"></img>
        </Link>
      </div>

      {/* 우측 */}
      <div className="header-wrap">
        {!userStore.checkStatus().isLogin ? (
          <>
            <Link
              to="/Login"
              onClick={() => {
                localStorage.removeItem("jwtToken");
                userStore.setLogout();
              }}
            >
              로그인
            </Link>
            <span>|</span>
            <Link to="/Signup" state={{ step: 1 }}>
              회원가입
            </Link>
          </>
        ) : (
          <>
            <div className="header-profile" ref={refP} onClick={onClickProfile}>
              <div className="header-profile-none">
                <img
                  src={
                    ImageComponent[
                      "Profile_user" + userStore.checkStatus().userClass
                    ]
                  }
                  className="img_profile"
                ></img>
                <span className="header-profile-name clamp1">
                  {userStore.checkStatus().userName}
                </span>
                <span> 님</span>
              </div>
              {profile ? (
                <ProfileComponent onClickProfile={onClickProfile} />
              ) : null}
            </div>

            <div className="header-alarm" ref={refA}>
              {alarm ? (
                <>
                  <img
                    src={ImageComponent.Close}
                    className="img_close"
                    onClick={() => {
                      onClickCAlarm();
                    }}
                  ></img>
                  <AlarmComponent
                    alarm={alarm}
                    setAlarm={setAlarm}
                    alarms={alarms}
                    reload={reload}
                    setReload={setReload}
                  />
                </>
              ) : (
                <img
                  src={
                    alarms && alarms.length > 0
                      ? ImageComponent.Alarm_On
                      : ImageComponent.Alarm_Off
                  }
                  className="img_alarm"
                  onClick={() => {
                    onClickCAlarm();
                  }}
                ></img>
              )}
            </div>

            <div className="header-calendar" ref={refC}>
              {calendar ? (
                <>
                  <img
                    src={ImageComponent.Close}
                    className="img_close"
                    onClick={() => {
                      onClickCalendar();
                    }}
                  ></img>
                  <CalendarComponent className={"calendar shadow"} />
                </>
              ) : (
                <img
                  src={ImageComponent.Calendar}
                  className="img_calendar"
                  onClick={() => {
                    onClickCalendar();
                  }}
                ></img>
              )}
            </div>
          </>
        )}
      </div>
      <div
        className={
          hamburgerMenu ? "header-wrap-mobile active" : "header-wrap-mobile"
        }
        onClick={handleHamburgerModal}
      >
        <div className={hamburgerMenu ? "menu-icon-open" : "menu-icon"}>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      {hamburgerMenu && (
        <div className="backdrop" onClick={handleHamburgerModal}></div>
      )}
      <div className={hamburgerMenu ? "menu-list open" : "menu-list"}>
        <article className="user-info">
          {!userStore.checkStatus().isLogin ? (
            <div className="user-info-not-logged">
              <Link
                to="/Login"
                onClick={() => {
                  localStorage.removeItem("jwtToken");
                  userStore.setLogout();
                  handleHamburgerModal();
                }}
              >
                로그인
              </Link>
              <span>|</span>
              <Link
                to="/Signup"
                onClick={handleHamburgerModal}
                state={{ step: 1 }}
              >
                회원가입
              </Link>
            </div>
          ) : (
            <>
              <div className="user-name-wrap">
                <img
                  src={
                    ImageComponent[
                      "Profile_user" + userStore.checkStatus().userClass
                    ]
                  }
                  className="img_profile"
                ></img>
                <Link
                  to="/Mypage?type=write&currentPage=1"
                  className="header-profile-name"
                  onClick={handleHamburgerModal}
                >
                  {userStore.checkStatus().userName}
                </Link>
              </div>
              <div className="header-user-bottom">
                <div className="header-user-detail">
                  <span>
                    {userStore.checkStatus().userDep}{" "}
                    {userStore.checkStatus().userDepNo}기
                  </span>
                  <span className="header-user-detail-grade">
                    {gradeList[userStore.checkStatus().userClass]}
                  </span>
                </div>
                <button
                  className="header-logout"
                  onClick={() => {
                    handleHamburgerModal();
                    localStorage.removeItem("jwtToken");
                    userStore.setLogout();
                    navigate("/");
                    window.location.reload();
                  }}
                >
                  로그아웃
                </button>
              </div>
            </>
          )}
          <hr></hr>
        </article>
        <article className="gnb-info">
          <GlobalNavMenuMobile closeHamburgerModal={handleHamburgerModal} />
        </article>
      </div>
    </div>
  );
}

export default observer(Header);
