import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";

import "./ForumComponent.css";

import ImageComponent from "../common/ImageComponent";
import { lnbIdList } from "../common/lnbID";
import { getLnbName } from "../common/lnbID";

import PagingComponent from "../post/PagingComponent";
import store from "../../store";

import ConvertForumDateStyle from "./ConvertForumDateStyle";

function ForumListComponent() {
    const nowParams = useParams();

    const { userStore } = store;

    //현재 페이지 설정 쿼리스트링
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("search");
    const currentPage = searchParams.get("currentPage");
    const postNo = searchParams.get("postNo");
    const type = searchParams.get("type");

    //데이터 가져오기
    const [forums, setForums] = useState([]);
    const [posts, setPosts] = useState([]);

    //페이지네이션
    const [totalPage, setTotalPage] = useState([[]]);

    useLayoutEffect(() => {
        if (nowParams.Category) {
            if (nowParams.Sub === "Q&A") {
                axios
                    .get(
                        process.env.REACT_APP_API_HOST +
                            "/api/posts/pagination/qna/" +
                            lnbIdList[nowParams.Sub],
                        {
                            params: {
                                search: search,
                                page: currentPage,
                                perPage: 10,
                            },
                        }
                    )
                    .then((response) => {
                        setForums(response.data.data); //현재페이지, 퍼페이지, 총페이지, 게시글들
                        setPosts(response.data.data.posts);
                        const pageArray = Array.from(
                            { length: response.data.data.totalPage },
                            (v, i) => i + 1
                        );
                        const pageArr = [];
                        for (let i = 0; i < pageArray.length; i += 10) {
                            pageArr.push(pageArray.slice(i, i + 10));
                        }

                        // 페이지 나누기..
                        if (pageArr.length !== 0) {
                            setTotalPage(pageArr);
                        } else {
                            setTotalPage([[]]);
                        }
                    });
            } else {
                //게시글일때
                axios
                    .get(
                        process.env.REACT_APP_API_HOST +
                            "/api/posts/pagination/" +
                            lnbIdList[nowParams.Sub],
                        {
                            params: {
                                search: search,
                                page: currentPage,
                                perPage: 10,
                            },
                        }
                    )
                    .then((response) => {
                        setForums(response.data.data); //현재페이지, 퍼페이지, 총페이지, 게시글들
                        setPosts(response.data.data.posts);
                        const pageArray = Array.from(
                            { length: response.data.data.totalPage },
                            (v, i) => i + 1
                        );
                        const pageArr = [];
                        for (let i = 0; i < pageArray.length; i += 10) {
                            pageArr.push(pageArray.slice(i, i + 10));
                        }

                        // 페이지 나누기..
                        if (pageArr.length !== 0) {
                            setTotalPage(pageArr);
                        } else {
                            setTotalPage([[]]);
                        }
                    });
            }
        } else if (type === "write") {
            axios
                .get(
                    process.env.REACT_APP_API_HOST +
                        "/api/posts/mywrite/pagination/" +
                        userStore.checkStatus().userNo,
                    {
                        params: {
                            search: search,
                            page: currentPage,
                            perPage: 10,
                        },
                    }
                )
                .then((response) => {
                    setForums(response.data.data); //현재페이지, 퍼페이지, 총페이지, 게시글들
                    setPosts(response.data.data.posts);
                    const pageArray = Array.from(
                        { length: response.data.data.totalPage },
                        (v, i) => i + 1
                    );
                    const pageArr = [];
                    for (let i = 0; i < pageArray.length; i += 10) {
                        pageArr.push(pageArray.slice(i, i + 10));
                    }
                    // 페이지 나누기..
                    if (pageArr.length !== 0) {
                        setTotalPage(pageArr);
                    } else {
                        setTotalPage([[]]);
                    }
                });
        } else if (type === "like") {
            axios
                .get(
                    process.env.REACT_APP_API_HOST +
                        "/api/posts/mylike/pagination/" +
                        userStore.checkStatus().userNo,
                    {
                        params: {
                            search: search,
                            page: currentPage,
                            perPage: 10,
                        },
                    }
                )
                .then((response) => {
                    setForums(response.data.data); //현재페이지, 퍼페이지, 총페이지, 게시글들
                    setPosts(response.data.data.posts);
                    const pageArray = Array.from(
                        { length: response.data.data.totalPage },
                        (v, i) => i + 1
                    );
                    const pageArr = [];
                    for (let i = 0; i < pageArray.length; i += 10) {
                        pageArr.push(pageArray.slice(i, i + 10));
                    }

                    // 페이지 나누기..
                    if (pageArr.length !== 0) {
                        setTotalPage(pageArr);
                    } else {
                        setTotalPage([[]]);
                    }
                });
        }
    }, [nowParams, search, currentPage, type, userStore.checkStatus().isLogin]);

    //게시글 하나하나 => 카테고리별 게시글로 api수정
    const fl = posts.map((post) => {
        return (
            <div
                key={post.post_no}
                className={
                    post.post_no == postNo
                        ? "list-container now_post shadow"
                        : "list-container shadow"
                }
            >
                <Link
                    to={`/${
                        nowParams.Category || getLnbName(post.cat_parent_no)
                    }/${
                        nowParams.Sub || getLnbName(post.cat_no)
                    }/post/?postNo=${post.post_no}&search=${
                        search || ""
                    }&currentPage=${currentPage}`}
                >
                    {post.post_is_important ? (
                        <div className="forum-important">
                            <img
                                src={ImageComponent.Star}
                                className="img_star"
                            ></img>
                            <img
                                src={ImageComponent.Star_White}
                                className="img_starwhite"
                            ></img>
                        </div>
                    ) : (
                        <span className="forum-num">{post.idx}</span>
                    )}
                    <span className="forum-title clamp1">{post.title}</span>
                    <div className="forum-name">
                        <img
                            src={
                                ImageComponent["Profile_user" + post.user_class]
                            }
                        />
                        <p className="clamp1">{post.user_nick}</p>
                    </div>
                    <span className="forum-view clamp1">{post.post_hit}</span>
                    <span className="forum-date clamp1">
                        {ConvertForumDateStyle(post.post_ins_date)}
                    </span>
                </Link>
            </div>
        );
    });

    return (
        <div className="forum-main">
            <div className="forum-top">
                <span className="top-num">번호</span>
                <span className="top-title">제목</span>
                <span className="top-name">이름</span>
                <span className="top-view">조회</span>
                <span className="top-date">등록일</span>
            </div>

            <hr />

            <div className="forum-list">
                {posts.length != 0 ? (
                    fl
                ) : (
                    <p className="no_data">등록된 게시글이 없습니다.</p>
                )}
            </div>

            <div className="forum-bottom">
                <button
                    className="btn-totop"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }}
                >
                    <img src={ImageComponent.Top} className="img_top"></img>
                    <span>TOP</span>
                </button>
                <Link
                    to={`/${nowParams.Category || ""}/${
                        nowParams.Sub || ""
                    }/editor`}
                >
                    <button className="btn-write">글쓰기</button>
                </Link>
            </div>
            {totalPage[0].length != 0 ? (
                <PagingComponent
                    currentPage={currentPage}
                    totalPage={totalPage}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                ></PagingComponent>
            ) : null}
        </div>
    );
}

export default observer(ForumListComponent);
