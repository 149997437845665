import React from "react";
import ImageComponent from "../common/ImageComponent";
import AdminUserComponent from "./users/AdminUserComponent";
import AdminMenuComponent from "./AdminMenuComponent";
import AdminCalendarComponent from "./calendar/AdminCalendarComponent";
import AdminBrandComponent from "./brands/AdminBrandComponent";
import AdminIntroComponent from "./change/AdminIntroComponent";
import "./AdminPage.css";
import { useParams } from "react-router-dom";
import AdminHeaderComponent from "./AdminHeaderComponent";
import AdminPopupComponent from "./AdminPopupComponent";
import AdminYoutubeComponent from "./AdminYoutubeComponent";
import AdminBIComponent from "./change/AdminBIComponent";
import AdminRulesComponent from "./change/AdminRulesComponent";
import AdminHistoryComponent from "./multi/AdminHistoryComponent";
import AdminPeopleComponent from "./multi/AdminPeopleComponent";
import AdminRecruitComponent from "./multi/AdminRecruitComponent";
import AdminNoticeComponent from "./multi/AdminNoticeComponent";

export default function AdminPage(props) {
  const nowParams = useParams(); //주소에서 /:Category/:Sub

  const toLink = () => {
    switch (nowParams.Path) {
      case "Users":
        return <AdminUserComponent />;
      case "Calendar":
        return <AdminCalendarComponent title={nowParams.Path} />;
      case "Brand":
        return <AdminBrandComponent title={nowParams.Path} />;
      case "Youtube":
        return <AdminYoutubeComponent title={nowParams.Path} />;
      case "Popup":
        return <AdminPopupComponent title={nowParams.Path} />;
      case "Header":
        return <AdminHeaderComponent title={nowParams.Path} />;
      case "Intro":
        return <AdminIntroComponent title={nowParams.Path} />;
      case "BI":
        return <AdminBIComponent title={nowParams.Path} />;
      case "Rules":
        return <AdminRulesComponent title={nowParams.Path} />;
      case "History":
        return <AdminHistoryComponent title={nowParams.Path} />;
      case "People":
        return <AdminPeopleComponent title={nowParams.Path} />;
      case "Recruit":
        return <AdminRecruitComponent title={nowParams.Path} />;
      case "Recruit":
        return <AdminRecruitComponent title={nowParams.Path} />;
      case "Notice":
        return <AdminNoticeComponent title={nowParams.Path} />;
      default:
        return;
    }
  };

  return (
    <div className="admin">
      <AdminMenuComponent />
      {toLink()}
    </div>
  );
}
