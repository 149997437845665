import axios from "axios";
import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../common/ImageComponent.js";
import "./GlobalNavMenuMobile.css";

function GlobalNavMenuMobile({ closeHamburgerModal }) {
    // 메인 배경 그라데이션 설정하기위해 gnb에 관련함수
    const location = useLocation();
    function addClassName() {
        if (location["pathname"] !== "/") {
            document.getElementById("all").className = "";
        } else {
            document.getElementById("all").className = "mainpage";
        }
    }

    useEffect(() => {
        addClassName();
    });

    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_HOST + "/api/categories/")
            .then((response) => {
                setData(response.data.data);
            });
    }, []);

    let gnbDict = {};
    let parentDict = {};
    data.map((gl) => {
        //부모 요소 따로
        if (gl.cat_parent_no == null) {
            parentDict[gl.cat_no] = gl.cat_name;
        }
        //parent별로 나누기 gnbDict에 저장
        if (gnbDict[gl.cat_parent_no] == null) {
            gnbDict[gl.cat_parent_no] = [[gl.cat_no, gl.cat_name]];
        } else {
            const arr = gnbDict[gl.cat_parent_no];
            arr.push([gl.cat_no, gl.cat_name]);
            if (Array.isArray(arr)) gnbDict[gl.cat_parent_no] = arr;
        }
    });

    const gnbList = Object.entries(gnbDict); //딕셔너리를 리스트로 변경
    const parentList = Object.entries(parentDict); //딕셔너리를 리스트로 변경

    return (
        <ul className="site-menu">
            {parentList.map((title) => (
                <li key={title[0]} className="site-menu-items">
                    <input type="checkbox" id={title[1]} name="gnb-mobile" />
                    <label htmlFor={title[1]} className="menu-title">
                        {title[1]}
                    </label>
                    <ul className="menu-items">
                        {gnbList[title[0] - 1][1].map((value, index) => (
                            <li
                                key={index}
                                onClick={() =>
                                    closeHamburgerModal((prev) => !prev)
                                }
                            >
                                <Link
                                    className="menu-item"
                                    to={`/${title[1]}/${value[1]}?currentPage=1`}
                                >
                                    {value[1]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </li>
            ))}
        </ul>
    );
}

export default GlobalNavMenuMobile;
