import React from "react";
import "react-quill/dist/quill.snow.css";
import "./Editor.css";
import "react-quill/dist/quill.snow.css"; // ES6

// 읽기전용
function EditorReadComponent(props) {
  let ReadQuill =
    typeof window === "object" ? require("react-quill") : () => false;
  const modules = {
    toolbar: false,
    // style: true, // Add this line to display styles in the content
  };
  return (
    <ReadQuill
      className="quill-read"
      modules={modules}
      value={props.content}
      readOnly
    />
  );
}
export default EditorReadComponent;
