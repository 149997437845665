import { React, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import "./PostComponent.css";
import PostContentComponent from "./PostContentComponent";
import ImageComponent from "../common/ImageComponent";
import HeartListComponent from "./HeartListComponent";
import CommentComponent from "./CommentComponent";
import ForumListComponent from "../forum/ForumListComponent";
import axios from "axios";
import Modal from "../Modal/Modal";
import store from "../../store";
import CheckModal from "../Modal/CheckModal";

function PostComponent() {
  const navigate = useNavigate();
  const { userStore } = store;
  const [userNo, setUserNo] = useState(null);
  const nowParams = useParams();
  // 게시글 하나
  const [commentsCount, setCommentsCount] = useState();

  //쿼리스트링
  const [searchParams, setSearchParams] = useSearchParams();
  const postNo = searchParams.get("postNo"); //게시글번호
  useEffect(() => {
    const postNo = searchParams.get("postNo"); //게시글번호
  }, [searchParams]);

  // 권한
  const [userInfo, setUserInfo] = useState(userStore.checkStatus());
  useEffect(() => {
    setUserInfo(userStore.checkStatus());
  }, [userStore]);

  //모달
  const [modal, setModal] = useState(false);
  const [contentid, setContentID] = useState();
  const [modalType, setModalType] = useState(); //모달 버튼개수
  const [modalEvent, setModalEvent] = useState(); //모달이 가질 이벤트

  // 비번 확인 모닱
  const [checkModal, setCheckModal] = useState(false);
  const [checkModalEvent, setCheckModalEvent] = useState(); //모달이 가질 이벤트
  const [checkContentId, setCheckContentId] = useState();
  // 수정 클릭
  const onClickUpdate = (e) => {
    if (
      userStore.userClass === 5 ||
      (userInfo.userNo === userNo && userInfo.userNo)
    ) {
      navigate(
        `/${nowParams.Category}/${nowParams.Sub}/editor?postNo=${postNo}`
      );
    } else {
      setCheckModal(!checkModal);
      setCheckContentId(2);
      setCheckModalEvent(() => {
        const update = () => {
          navigate(
            `/${nowParams.Category}/${nowParams.Sub}/editor?postNo=${postNo}`
          );
        };
        return update;
      });
    }
  };

  // 삭제클릭
  const onClickDelete = (e) => {
    if (
      userStore.userClass === 5 ||
      (userInfo.userNo === userNo && userInfo.userNo)
    ) {
      setModalType(2);
      setContentID(6);
      setModalEvent(() => {
        const deleteC = () => {
          DeletePost(e);
        };
        return deleteC;
      });
      setModal(!modal);
    } else {
      setCheckModal(!checkModal);
      setCheckContentId(1);

      setCheckModalEvent(() => {
        const deleteC = () => {
          DeletePost();
        };
        return deleteC;
      });
    }
  };

  //게시글삭제
  const DeletePost = (e) => {
    axios
      .delete(process.env.REACT_APP_API_HOST + "/api/posts/delete/" + postNo)
      .then((response) => {
        setModalType(1);
        setContentID(14);
        setModalEvent(() => {
          const reloadPage = () => {
            setModal(true);
            navigate(`/${nowParams.Category}/${nowParams.Sub}?currentPage=1`);
          };
          return reloadPage;
        });
        setModal(!modal);
      })
      .catch((e) => console.log(e));
  };

  const buttons = () => {
    if (
      userInfo.userNo === userNo ||
      userInfo.userClass === 5 ||
      nowParams.Sub === "Q&A"
    ) {
      return (
        <>
          <button
            className="only-write"
            onClick={() => {
              onClickUpdate();
            }}
          >
            수정
          </button>
          <button
            className="only-write"
            onClick={(e) => {
              onClickDelete(e);
            }}
          >
            삭제
          </button>
        </>
      );
    }
  };

  return (
    <div className="post">
      {/* 게시글내용 */}
      <PostContentComponent
        commentsCount={commentsCount}
        postNo={postNo}
        setUserNo={setUserNo}
      />
      <div className="post-btns">
        {buttons()}
        <button
          className="btn-totop"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <img src={ImageComponent.Top} className="img_top"></img>
          <span>TOP</span>
        </button>
        <Link to={`/${nowParams.Category}/${nowParams.Sub}/editor`}>
          <button className="btn-write">글쓰기</button>
        </Link>
      </div>

      <div className="post-like">
        {nowParams.Sub !== "Q&A" ? (
          <>
            <hr></hr>
            <HeartListComponent postNo={postNo} />
          </>
        ) : null}
        <hr></hr>
        <CommentComponent
          postNo={postNo}
          commentsCount={commentsCount}
          setCommentsCount={setCommentsCount}
        />
        {/* 댓글 */}
        <hr className="hr-bottom"></hr>
      </div>

      <ForumListComponent />

      <CheckModal
        open={checkModal}
        close={() => {
          setCheckModal(!checkModal);
        }}
        postNo={postNo}
        checkModalEvent={checkModalEvent}
        checkContentId={checkContentId}
      />

      <Modal
        open={modal}
        close={() => {
          setModal(!modal);
        }}
        type={modalType}
        content={contentid}
        modalEvent={modalEvent}
      />
    </div>
  );
}

export default PostComponent;
