import axios from "axios";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ImageComponent from "../common/ImageComponent";
import "../LoginPage.css";
import Modal from "../Modal/Modal";
import getAuthorization from "../utils/getAuthorization";
import store from "../../store/index";
import { useCookies } from "react-cookie";

function LoginPage() {
    //쿠키
    const [cookies, setCookie, removeCookie] = useCookies(["saveAdpowerId"]);
    const [isLoading, setIsLoading] = useState(false); // 정보를 가져올 때 로딩 상태를 보여주기 위한 state

    //store
    const { loadingStore } = store;
    const navigate = useNavigate();

    const [Checked1, setChecked1] = useState(false);
    const [CheckedId, setCheckedId] = useState(false);
    const checkHandler = (e) => {
        if (e.target.id == "chk_1") {
            setChecked1(e.target.checked);
        } else if (e.target.id == "chk_2") {
            setCheckedId(e.target.checked);
        }
    };

    const [idValue, setIdValue] = useState(""); //아이디 입력
    const handleIdValue = (e) => {
        setIdValue(e.target.value);
    };
    const [pwValue, setPwValue] = useState(""); //비밀번호 입력
    const handlePwValue = (e) => {
        setPwValue(e.target.value);
    };
    const [modal, setModal] = useState(false); //모달 온오프
    const [modalType, setModalType] = useState(); //모달 버튼개수
    const [contentid, setContentID] = useState(); //모달이 나타낼 글

    useEffect(() => {
        if (cookies.saveAdpowerId !== undefined) {
            setIdValue(cookies.saveAdpowerId);
            setCheckedId(true);
        }
    }, []);

    //로그인 버튼 누를때
    const handleLogin = () => {
        loadingStore.setIstLoading(true);
        axios
            .post(process.env.REACT_APP_API_HOST + "/api/users/signin", {
                param: {
                    id: idValue,
                    password: pwValue,
                },
            })
            .then((response) => {
                if (response.data.status === "false") {
                    setModal(!modal);
                    setModalType(1);
                    setContentID(response.data.message);
                } else {
                    if (CheckedId) {
                        setCookie("saveAdpowerId", idValue, { maxAge: 2000 });
                    } else {
                        removeCookie("saveAdpowerId");
                    }
                    const token = response.data.data;
                    localStorage.setItem("jwtToken", token);
                    getAuthorization(token, setIsLoading);
                    navigate("/");
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => loadingStore.setIstLoading(false));
    };

    const handleOnKeyPress = (e) => {
        if (e.key === "Enter") {
            handleLogin(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    return (
        <div className="login-container">
            <div className="shadow login-box">
                <img
                    src={ImageComponent.LogoSvg.default}
                    className="img_logo"
                ></img>
                <div className="login-center">
                    <input
                        className="login-input"
                        placeholder="아이디"
                        value={idValue}
                        onChange={(e) => handleIdValue(e)}
                        onKeyDown={handleOnKeyPress}
                    ></input>
                    <input
                        type={"password"}
                        className="login-input"
                        placeholder="비밀번호"
                        value={pwValue}
                        onChange={(e) => handlePwValue(e)}
                        onKeyDown={handleOnKeyPress}
                    ></input>
                    <div className="login-checkbox">
                        <div className="checkbox-container">
                            <input
                                type={"checkbox"}
                                id="chk_1"
                                onChange={(e) => checkHandler(e)}
                            ></input>
                            <label htmlFor="chk_1">
                                {Checked1 ? (
                                    <img
                                        src={ImageComponent.CheckBox_On}
                                        className="img_checkBox"
                                    ></img>
                                ) : (
                                    <img
                                        src={ImageComponent.CheckBox_Off}
                                        className="img_checkBox"
                                    ></img>
                                )}
                            </label>
                            <label htmlFor="chk_1">
                                <span className="chk_1">로그인 상태 유지</span>
                            </label>
                        </div>
                        <div className="checkbox-container">
                            <input
                                type={"checkbox"}
                                id="chk_2"
                                onChange={(e) => checkHandler(e)}
                            ></input>
                            <label htmlFor="chk_2">
                                {CheckedId ? (
                                    <img
                                        src={ImageComponent.CheckBox_On}
                                        className="img_checkBox"
                                    ></img>
                                ) : (
                                    <img
                                        src={ImageComponent.CheckBox_Off}
                                        className="img_checkBox"
                                    ></img>
                                )}
                            </label>
                            <label htmlFor="chk_2">
                                <span className="chk_2">아이디 저장</span>
                            </label>
                        </div>
                    </div>
                    <button
                        className="btn-write btn-login"
                        onClick={() => handleLogin()}
                    >
                        로그인
                    </button>
                </div>
            </div>

            <div className="login-finds">
                <span
                    className="login-find"
                    onClick={() => {
                        navigate("/Find?type=id");
                    }}
                >
                    아이디 찾기
                </span>
                <span>|</span>
                <span
                    className="login-find"
                    onClick={() => {
                        navigate("/Find?type=pw");
                    }}
                >
                    비밀번호 찾기
                </span>
                <span>|</span>
                <Link to="/signup" className="login-find">
                    회원가입
                </Link>
            </div>

            <Modal
                open={modal}
                close={() => {
                    setModal(!modal);
                }}
                type={1}
                content={contentid}
            />
        </div>
    );
}

export default LoginPage;
